import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import PartSearchInput from "../../../components/Employee/PartSearchInput";
import PartList from "../../../components/Employee/PartList";
import { withAuthUser } from "../../../services/Authentication";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { PartsProvider, withParts } from "../../../services/providers/Parts";
import ROLES from "../../../constants/ROLES";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import { PropTypePart } from "../../../prop-types/part";
import { getEmployeeIdFromEmail } from "../../../helpers/user";
import { scrollTop, snapScrollCheckiOS } from "../../../helpers/iosscroll";
import "./index.less";

const NoSearchResult = () => (
  <div className="empty-results">
    <SearchOutlined className="empty-icon" />
    <Text className="empty-message">Type in your search</Text>
  </div>
);

const { Text } = Typography;
const SearchPartsPage = ({ firebase, authUser, parts }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredParts, setFilteredParts] = useState([]);
  const [myPartsOnly, setMyPartsOnly] = useState(false);
  const email = authUser ? authUser.email : "";
  const employeeId = getEmployeeIdFromEmail(email);

  const getFilteredList = (isMyParts, filterTerm, partsList) => {
    if (!filterTerm && !isMyParts) return [];
    const lowerCasedSearchTerm = _.lowerCase(filterTerm);
    return _.filter(partsList, ({ partTag, poNumber, lastLocationEntry }) => {
      if (isMyParts && lastLocationEntry.employeeId !== employeeId)
        return false;
      if (!filterTerm) return true;
      const checkPartTag = _.includes(
        _.lowerCase(partTag),
        lowerCasedSearchTerm,
      );
      const checkPoNumber = _.includes(
        _.lowerCase(poNumber),
        lowerCasedSearchTerm,
      );
      return checkPartTag || checkPoNumber;
    });
  };

  useEffect(() => {
    if (snapScrollCheckiOS) {
      scrollTop();
    }
  }, []);

  useEffect(async () => {
    const newList = getFilteredList(myPartsOnly, searchTerm, parts);
    setFilteredParts(newList);
  }, [parts, myPartsOnly]);

  const handleSearchSubmit = (term) => {
    setSearchTerm(term);
    const newList = getFilteredList(myPartsOnly, term, parts);
    setFilteredParts([...newList]);
  };

  const handleMyPartsSwitch = (checked) => {
    setMyPartsOnly(checked);
    const newList = getFilteredList(checked, searchTerm, parts);
    setFilteredParts([...newList]);
  };

  return (
    <div id="search-parts-page-container">
      <h1> Parts Search{searchTerm ? `: ${searchTerm}` : ""}</h1>
      <div className="part-list-container">
        <PartList
          parts={filteredParts}
          authUser={authUser}
          firebase={firebase}
          EmptyResult={!searchTerm ? NoSearchResult : null}
          canEditPart
        />
      </div>
      <div className="my-parts-switch-container">
        <Text type="secondary">View My Last Drop-offs Only</Text>
        <Switch checked={myPartsOnly} onChange={handleMyPartsSwitch} />
      </div>
      <div className="part-search-input-container">
        <PartSearchInput onSubmit={handleSearchSubmit} />
      </div>
    </div>
  );
};

SearchPartsPage.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  parts: PropTypes.arrayOf(PropTypePart),
  authUser: AuthUserPropType,
};

SearchPartsPage.defaultProps = {
  parts: [],
  authUser: null,
};

const pageRole = ROLES.employee;

export default withFirebase(
  withAuthUser(PartsProvider(pageRole)(withParts(SearchPartsPage))),
);
export { SearchPartsPage as SearchPartsPageExposed };
