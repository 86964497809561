/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import {
  UserAddOutlined,
  TabletOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import ROLES from "../../../../constants/ROLES";
import "./index.less";

const { Text } = Typography;

const SelectUserType = ({ selectedUser, setSelectedUser }) => (
  <div className="select-user-type-container">
    <div className="user-type-selector-container">
      <div
        className={`user-type-selector ${
          selectedUser === ROLES.employee ? "selected" : ""
        }`}
        onClick={() => setSelectedUser(ROLES.employee)}>
        <UserOutlined className="user-type-selector-icon" />
      </div>
      <Text className="user-type-selector-text" strong>
        {" "}
        New {ROLES.employee}
      </Text>
    </div>
    <div className="user-type-selector-container">
      <div
        className={`user-type-selector ${
          selectedUser === ROLES.tablet ? "selected" : ""
        }`}
        onClick={() => setSelectedUser(ROLES.tablet)}>
        <TabletOutlined className="user-type-selector-icon" />
      </div>
      <Text className="user-type-selector-text" strong>
        New
      </Text>
      <Text strong> {ROLES.tablet} User</Text>
    </div>
    <div className="user-type-selector-container">
      <div
        className={`user-type-selector ${
          selectedUser === ROLES.admin ? "selected" : ""
        }`}
        onClick={() => setSelectedUser(ROLES.admin)}>
        <UserAddOutlined className="user-type-selector-icon" />
      </div>
      <Text className="user-type-selector-text" strong>
        {" "}
        New {ROLES.admin}
      </Text>
    </div>
  </div>
);

SelectUserType.propTypes = {
  selectedUser: PropTypes.oneOf(Object.values(ROLES)),
  setSelectedUser: PropTypes.func.isRequired,
};

SelectUserType.defaultProps = {
  selectedUser: ROLES.employee,
};

export default SelectUserType;
