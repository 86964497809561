import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Image, Button } from "antd";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Firebase, withFirebase } from "../../../services/Firebase";

import * as ROUTES from "../../../constants/routes";
import "./index.less";

const AdminNavigationBar = ({ firebase }) => {
  const [activeItem, setActiveItem] = useState();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && _.includes(ROUTES, location.pathname))
      setActiveItem(location.pathname);
  }, []);

  function handleSelect({ key }) {
    setActiveItem(key);
    history.push(key);
  }

  return (
    <div id="admin-navigation-bar">
      <Menu
        onClick={handleSelect}
        selectedKeys={[activeItem]}
        mode="horizontal">
        <Menu.Item key="#" id="admin-navigation-bar-logo" disabled>
          <Image
            preview={false}
            alt="Kekuli Bay Cabinetry Logo"
            src="/kbc-logo.png"
          />
        </Menu.Item>
        <Menu.Item key={ROUTES.ADMIN_USER_MANAGEMENT}>
          User Management
        </Menu.Item>
        <Menu.Item key={ROUTES.ADMIN_PARTS_MANAGEMENT}>
          Parts Management
        </Menu.Item>
        <Menu.Item id="admin-navigation-logout" key="admin-logout" disabled>
          <Button type="link" onClick={firebase.doSignOut}>
            Logout
          </Button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

AdminNavigationBar.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export default withFirebase(AdminNavigationBar);
