export default {
  programming: "Programming",
  cnc: "CNC",
  "edge band": "Edge Band",
  sand: "Sand",
  finishing: "Finishing",
  "quality control": "Quality Control",
  "door drilling": "Door Drilling",
  "cabinet build table": "Cabinet Build Table",
  "door build": "Door Build",
  "drawer box build": "Drawerbox Build",
  "glue up": "Glue-up",
  packaging: "Packaging",
  wrapped: "Wrapped",
  shipped: "Shipped",
  stock: "Stock",
  ordered: "Ordered",
  "kitchen pull": "Kitchen Pull",
  // lodash lowerCase removes '#' and doesnt replace it with a space
  "custom work bench 1": "Custom Work Bench #1",
  "custom work bench 2": "Custom Work Bench #2",
};
