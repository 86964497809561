import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, Modal, Typography, Input } from "antd";
import { Firebase, withFirebase } from "../../services/Firebase";
import "./index.less";

const { Title, Text } = Typography;

const RecoverPasswordModal = ({ firebase, visible, onClose }) => {
  const [currentValue, setCurrentValue] = useState("");
  const [id, setId] = useState("");
  const employeeTabletResetMsg =
    "Please speak with an Admin to recover your password.";
  const adminResetMsg =
    "If this email is valid, an email has just been sent with a password recovery link.";

  async function requestPasswordReset() {
    await firebase.requestPasswordReset(currentValue);
    setId(currentValue);
  }

  function handleChange(e) {
    setCurrentValue(e.target.value);
  }

  function handleClose() {
    setId("");
    setCurrentValue("");
    onClose();
  }

  const RecoverPasswordButton = () => (
    <Button type="primary" onClick={requestPasswordReset}>
      Recover Password
    </Button>
  );

  const recoverMessage =
    id && _.includes(id, "@") ? adminResetMsg : employeeTabletResetMsg;

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title={<Title level={3}>Recover Password</Title>}
      centered
      footer={!id ? <RecoverPasswordButton /> : null}>
      <div className="recover-password-modal-body">
        {!id ? (
          <>
            <Text strong className="label-text">
              Enter Username or Admin Email
            </Text>
            <Input
              onChange={handleChange}
              onPressEnter={requestPasswordReset}
              value={currentValue}
              allowClear
            />
          </>
        ) : (
          <Text>{recoverMessage}</Text>
        )}
      </div>
    </Modal>
  );
};

RecoverPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export default withFirebase(RecoverPasswordModal);
