import PropTypes from "prop-types";

const PropTypeUser = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  creationDate: PropTypes.instanceOf(Date).isRequired,
  lastActivityDate: PropTypes.instanceOf(Date).isRequired,
});

export default PropTypeUser;
