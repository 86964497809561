import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Badge, Typography, Space, message, Modal } from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import QRCodeScanner from "../../../components/Employee/QRCodeScanner";
import ManualLocationInput from "../../../components/Employee/ManualLocationInput";
import ManualPartInput from "../../../components/Employee/ManualPartInput";
import TabletDropOffList from "../../../components/Tablet/DropOffList";
import TabletResultModal from "../../../components/Tablet/TabletResultModal";
import SignDropOffModal from "../../../components/Tablet/SignDropOffModal";
import Branding from "../../../components/Cross/Branding";

import { handleLocationScan, handlePartScan } from "../../../helpers/scanner";

import { withAuthUser } from "../../../services/Authentication";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { withDropOffList } from "../../../services/providers/DropList";
import { withParts, PartsProvider } from "../../../services/providers/Parts";
import { withLocation } from "../../../services/providers/Location";
import { PropTypePart } from "../../../prop-types/part";
import ROLES from "../../../constants/ROLES";
import "./index.less";

const { Title, Text } = Typography;

const TabletScannerPage = ({
  parts,
  dropOffList,
  setDropOffList,
  firebase,
  location,
  setLocation,
}) => {
  const [activeEmployeeId, setActiveEmployeeId] = useState("");
  const [resultVisible, setResultVisible] = useState(false);
  const [confirmChangeLocation, setConfirmChangeLocation] = useState(false);
  const [signDropOff, setSignDropOff] = useState(false);

  const callHandleLocationScan = async (code) =>
    handleLocationScan(code, setLocation);

  const callHandlePartScan = async (code) =>
    handlePartScan(code, dropOffList, setDropOffList, parts, location);

  const handleError = (err) => {
    const msg =
      err && err.message ? err.message : "Scanner: There was an error";
    message.error(msg);
  };

  const handleSubmit = async (employeeId) => {
    if (!activeEmployeeId) {
      message.error("Please set Employee Id.", 4);
      return;
    }
    try {
      // setSaving(true);
      const result = await firebase.submitDropOffList(
        location,
        employeeId,
        dropOffList,
      );
      // setSaving(false);
      if (result && result.result === "success") {
        setActiveEmployeeId("");
        setDropOffList([]);
        setSignDropOff(false);
        setResultVisible(true);
      } else if (result) {
        message.error(result.message);
      }
      // setSaving(false);
    } catch (err) {
      if (err) message.error(err);
    }
  };

  const handleScan = async (code) =>
    location ? callHandlePartScan(code) : callHandleLocationScan(code);

  return (
    <div className="tablet-scanner-container">
      <SignDropOffModal
        visible={signDropOff}
        onClose={() => setSignDropOff(false)}
        employeeId={activeEmployeeId}
        setEmployeeId={setActiveEmployeeId}
        onDropOff={handleSubmit}
      />
      <TabletResultModal
        visible={resultVisible}
        onClose={() => {
          setResultVisible(false);
        }}
        content={{
          title: "Complete Drop-off",
          subHeader: "Drop-off Completed",
          message: "Parts have been updated",
          type: "success",
        }}
      />
      <Modal
        title={<Title level={2}>Changing Location</Title>}
        visible={confirmChangeLocation}
        centered
        onOk={() => {
          setLocation("");
          setDropOffList([]);
          setConfirmChangeLocation(false);
        }}
        onCancel={() => setConfirmChangeLocation(false)}>
        <div className="confirm-location-change-contents-container">
          <ExclamationCircleOutlined className="confirm-location-icon" />
          <Title level={3}>
            Are you sure you want to change your location?
          </Title>
          <Text className="warning-text" type="danger">
            This will delete your entire drop off list
          </Text>
        </div>
      </Modal>
      <div className="tablet-scanner-qr-code-scanner">
        <QRCodeScanner
          onScan={handleScan}
          onError={handleError}
          facingMode="user"
        />
        {location ? (
          <div className="edit-location-button-container">
            <Button
              type="primary"
              onClick={() => setConfirmChangeLocation(true)}
              icon={<EditOutlined />}>
              {location}
            </Button>
          </div>
        ) : null}
      </div>
      {/* {location && !activeEmployeeId ? (
        <div className="tablet-scanner-helper">
          <EmployeeIdInput
            activeEmployeeId={activeEmployeeId}
            setActiveEmployeeId={setActiveEmployeeId}
          />
        </div>
      ) : null} */}
      {location ? (
        <div className="tablet-scanner-helper">
          <div className="part-input-container">
            <Text className="label" strong>
              Part Tag ID (Manual Entry)
            </Text>
            <ManualPartInput
              size="large"
              enableHelperMessage={false}
              onSubmit={callHandlePartScan}
              onSubmitFailed={handleError}
            />
          </div>
          <div className="tablet-drop-off-list-container">
            <Title level={2}>Drop-off List</Title>
            <TabletDropOffList />
          </div>
          <div className="drop-off-details-container">
            <Space className="location-container">
              <Text>Location: </Text>
              <Text strong>{location}</Text>
            </Space>
            <div className="drop-off-button-container">
              <Badge count={dropOffList.length} showZero offset={[0, 0]}>
                <Button
                  type="primary"
                  size="large"
                  // loading={saving}
                  onClick={() => setSignDropOff(true)}
                  disabled={dropOffList.length === 0}
                  style={{ height: "auto" }}>
                  Complete Drop-off
                </Button>
              </Badge>
            </div>
          </div>
        </div>
      ) : null}
      {!location ? (
        <div className="tablet-scanner-helper-location">
          <div className="branding-location-input-container">
            <Branding />
            <div className="location-input-container">
              <ManualLocationInput
                onSubmit={callHandleLocationScan}
                onSubmitFailed={handleError}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

TabletScannerPage.propTypes = {
  parts: PropTypes.arrayOf(PropTypePart),
  dropOffList: PropTypes.arrayOf(PropTypePart).isRequired,
  setDropOffList: PropTypes.func.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
};

TabletScannerPage.defaultProps = {
  parts: [],
};

const pageRole = ROLES.tablet;
export default withFirebase(
  withAuthUser(
    withDropOffList(
      withLocation(PartsProvider(pageRole)(withParts(TabletScannerPage))),
    ),
  ),
);
export { TabletScannerPage as TabletScannerPageStory };
