import React from "react";
import { Image, Typography } from "antd";
import "./index.less";

const { Title } = Typography;

const Branding = () => (
  <div className="logo-app-name-container">
    <Image
      preview={false}
      alt="Kekuli Bay Cabinetry Logo"
      src="/kbc-logo.png"
    />
    <Title className="logo-title" level={5}>
      Parts Tracking Application
    </Title>
  </div>
);

export default Branding;
