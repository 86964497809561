import _ from "lodash";

export const getEmployeeIdFromEmail = (email) => {
  if (!email || !_.includes(email, "@")) {
    return "";
  }
  const splitEmail = _.split(email, "@");
  return splitEmail[0];
};

export const isEmailAddress = (input) => {
  try {
    /*
    Regular Expression from here:
    https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    */
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(input).toLowerCase());
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getUsers = () => {
  // TODO: kind of just put here to chill elint for only
  // exporting one function and not using default
};
