import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { withDropOffList } from "../../../services/providers/DropList";
import { PropTypePart } from "../../../prop-types/part";
import "./index.less";

const TabletDropOffList = ({ dropOffList, removeFromDropOffList }) => {
  const columns = [
    {
      title: "PO#",
      dataIndex: "poNumber",
      width: "14%",
    },
    {
      title: "Tag #",
      dataIndex: "partTag",
      width: "14%",
    },
    {
      title: "Last Location",
      dataIndex: ["lastLocationEntry", "location"],
      key: "lastLocation",
      width: "21%",
    },
    {
      title: "Color/Stain",
      dataIndex: "colour",
      width: "21%",
    },
    {
      title: "Part Description",
      dataIndex: "description",
      width: "22%",
    },
    {
      title: "",
      key: "delete",
      render: (_, record) => ({
        children: (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              removeFromDropOffList(record.partTag);
            }}
          />
        ),
        props: {
          width: "8%",
        },
      }),
    },
  ];

  return (
    <div className="tablet-drop-off-list">
      <Table
        rowKey={(record) => record.partTag}
        dataSource={dropOffList}
        columns={columns}
        pagination={false}
        scroll={{ y: "40vh" }}
        locale={{ emptyText: "No items in Drop-off List." }}
      />
    </div>
  );
};

TabletDropOffList.propTypes = {
  dropOffList: PropTypes.arrayOf(PropTypePart).isRequired,
  removeFromDropOffList: PropTypes.func.isRequired,
};

TabletDropOffList.propTypes = {};

export default withDropOffList(TabletDropOffList);
