import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Input, Button, Typography, notification } from "antd";
import Branding from "../../components/Cross/Branding";
import RecoverPasswordModal from "../../components/RecoverPasswordModal";
// import * as ROUTES from "../../constants/routes";
import { Firebase, withFirebase } from "../../services/Firebase";
import {
  scrollTop,
  snapScrollCheckiOS,
  addFocusOutEventListeners,
} from "../../helpers/iosscroll";
import "./index.less";

const { Text } = Typography;
const SignInFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recoverPasswordModal, setRecoverPasswordModal] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (snapScrollCheckiOS) {
      scrollTop();
    }
  }, []);

  useEffect(() => addFocusOutEventListeners(), [form]);

  const handleSubmit = (values) => {
    const { identifier, password } = values;
    setLoading(true);
    try {
      firebase
        .doSignInWithEmailAndPassword(identifier, password)
        .then(() => {
          form.resetFields();
          notification.success({
            message: "Successfully logged in!",
            key: "successful-login",
            duration: 2,
          });
          history.push("/");
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    } catch (err) {
      setLoading(false);
      setError({ message: "Login credentials are invalid" });
    }
  };

  return (
    <div className="form-base-container">
      {error ? <Text type="danger">Login Error: {error.message}</Text> : null}
      <RecoverPasswordModal
        visible={recoverPasswordModal}
        onClose={() => setRecoverPasswordModal(false)}
      />
      <Form
        form={form}
        name="sign-in"
        onFinish={handleSubmit}
        onFinishFailed={(err) => setError(err)}>
        <Form.Item
          name="identifier"
          rules={[
            {
              required: true,
              message: "Please enter your username",
            },
          ]}>
          <Input placeholder="username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input a password!",
            },
          ]}>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            className="forgot-password-link"
            type="link"
            onClick={() => setRecoverPasswordModal(true)}>
            <Text underline>Forgot Password?</Text>
          </Button>
          <Button
            className="log-in-button"
            loading={loading}
            onClick={form.submit}
            type="primary"
            htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const SignInForm = withFirebase(SignInFormBase);

SignInFormBase.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const SignInPage = () => (
  <div className="sign-in-page">
    <div className="branding-container">
      <Branding />
    </div>
    <div className="sign-in-form-container">
      <SignInForm />
    </div>
  </div>
);

export default withFirebase(SignInPage);
