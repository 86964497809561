import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.less";

const SearchInput = ({ onSubmit, onEmpty, placeholder, term, validator }) => {
  const [form] = Form.useForm();

  const handleFinish = ({ searchTerm }) => {
    onSubmit(searchTerm);
  };
  const handleChange = (e) => {
    if (e.target.value === "") {
      onEmpty();
    }
  };
  return (
    <Form form={form} onFinish={handleFinish}>
      <Form.Item name="searchTerm" rules={validator ? [{ validator }] : []}>
        <div className="search-input-button-container">
          <Input
            className="search-input"
            placeholder={placeholder}
            allowClear
            defaultValue={term}
            onChange={handleChange}
          />
          <Button
            className="search-button"
            htmlType="submit"
            icon={<SearchOutlined />}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEmpty: PropTypes.func,
  placeholder: PropTypes.string,
  term: PropTypes.string,
  validator: PropTypes.func,
};

SearchInput.defaultProps = {
  onEmpty: () => {},
  placeholder: "Search by Part Tag #",
  term: null,
  validator: null,
};

export default SearchInput;
