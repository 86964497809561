import React, { useState, useEffect } from "react";
import { Menu, Typography } from "antd";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { SearchOutlined, ScanOutlined, UserOutlined } from "@ant-design/icons";
import * as ROUTES from "../../constants/routes";
import "./index.less";

const { Title } = Typography;

const iconStyle = {
  fontSize: "18px",
  color: "white",
};

const SideMenu = () => {
  const [activeItem, setActiveItem] = useState(ROUTES.LANDING);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && _.includes(ROUTES, location.pathname))
      setActiveItem(location.pathname);
  }, []);

  function handleSelect({ key }) {
    setActiveItem(key);
    history.push(key);
  }

  return (
    <div id="employee-navigation-bar">
      <Menu
        onClick={handleSelect}
        selectedKeys={[activeItem]}
        mode="horizontal"
        style={{ borderBottom: "none" }}>
        <Menu.Item key={ROUTES.EMPLOYEE_PROFILE} className="menu-item">
          <div className="menu-contents">
            <UserOutlined style={iconStyle} />
            <Title level={5}>Profile</Title>
          </div>
        </Menu.Item>
        <Menu.Item key={ROUTES.EMPLOYEE_SCANNER} className="menu-item">
          <div className="menu-contents">
            <ScanOutlined style={iconStyle} />
            <Title level={5}>Scanner</Title>
          </div>
        </Menu.Item>
        <Menu.Item key={ROUTES.EMPLOYEE_SEARCH_PARTS} className="menu-item">
          <div className="menu-contents">
            <SearchOutlined style={iconStyle} />
            <Title level={5}>Search</Title>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SideMenu;
