import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout, Typography, Menu } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { withAuthUser } from "../../../services/Authentication";
import { withFirebase } from "../../../services/Firebase";

import NavigationBar from "../../../components/Admin/NavigationBar";
import "./index.less";

const { Sider, Content, Header } = Layout;
const { Title } = Typography;

const AdminPageWrapper = ({ SiderContent, PageContent, title }) => {
  const [collapsed, setCollapsed] = useState(false);
  function onCollapse(isCollapsed) {
    setCollapsed(isCollapsed);
  }
  return (
    <Layout id="admin-page-wrapper">
      <Header>
        <div id="admin-navigation-bar-container">
          <NavigationBar />
        </div>
      </Header>
      <Layout hasSider>
        <Sider
          id="admin-sider"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          width="383px">
          <Menu mode="inline">
            <Menu.Item
              id="filter-title"
              key="filter-title"
              disabled
              icon={<FilterOutlined className="title-icon" />}>
              <Title className="title-text">Filters</Title>
            </Menu.Item>
          </Menu>
          <Menu
            mode="inline"
            id="admin-sider-content"
            key="admin-sider-content">
            <SiderContent collapsed={collapsed} />
          </Menu>
        </Sider>
        <Content id="admin-content">
          <Header>
            <Title>{title}</Title>
          </Header>
          <Content id="admin-dynamic-content">
            <PageContent />
          </Content>
        </Content>
      </Layout>
    </Layout>
  );
};

AdminPageWrapper.propTypes = {
  SiderContent: PropTypes.func.isRequired,
  PageContent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withFirebase(withAuthUser(AdminPageWrapper));
