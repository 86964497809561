import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Button, Row, Col, Spin, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { withAuthUser } from "../../../services/Authentication";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { withDropOffList } from "../../../services/providers/DropList";
import { withLocation } from "../../../services/providers/Location";
import { PropTypePart } from "../../../prop-types/part";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import PartList from "../../../components/Employee/PartList";
import ResultModal from "../../../components/Cross/ResultModal";
import { getEmployeeIdFromEmail } from "../../../helpers/user";
import { snapScrollCheckiOS, scrollTop } from "../../../helpers/iosscroll";

import "./index.less";
import ConfirmDropOffModal from "../../../components/Employee/ConfirmDropOffModal";

const { Title, Text } = Typography;
const DropOffListPage = ({
  dropOffList,
  setDropOffList,
  firebase,
  authUser,
  removeFromDropOffList,
  location,
  setLocation,
}) => {
  const history = useHistory();
  const employeeId = authUser ? getEmployeeIdFromEmail(authUser.email) : null;
  const [saving, setSaving] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [confirmDropOff, setConfirmDropOff] = useState(false);

  useEffect(() => {
    if (snapScrollCheckiOS) {
      scrollTop();
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      const result = await firebase.submitDropOffList(
        location,
        employeeId,
        dropOffList,
      );
      setSaving(false);
      if (result && result.result === "success") {
        setDropOffList([]);
        setConfirmDropOff(false);
        setResultVisible(true);
      } else {
        message.error(`Failed to submit: ${result && result.message}`);
      }
    } catch (err) {
      setSaving(false);
      if (err) message.error(err);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const editLocation = () => {
    setLocation("");
    handleBack();
  };

  return (
    <div className="drop-off-list-page-container">
      <ConfirmDropOffModal
        visible={confirmDropOff}
        onClose={() => setConfirmDropOff(false)}
        onConfirm={handleSubmit}
        saving={saving}
      />
      <ResultModal
        visible={resultVisible}
        onClose={() => {
          setResultVisible(false);
          setLocation("");
          handleBack();
        }}
        result={{
          title: "Drop-off Completed",
          message:
            "Your parts have been updated! You will now be navigated back to the scan screen",
          isSuccessful: true,
        }}
      />
      <Row className="header-container">
        <Col span={3}>
          <Button
            className="back-button"
            icon={<LeftOutlined style={{ color: "inherit" }} />}
            onClick={handleBack}
          />
        </Col>
        <Col span={18}>
          <Title className="title" level={2}>
            Drop-off List
          </Title>
        </Col>
        <Col span={3} />
      </Row>
      <div className="part-list-container">
        <PartList
          parts={dropOffList}
          onDelete={removeFromDropOffList}
          firebase={firebase}
          EmptyResult={() => <div />}
        />
      </div>
      <div className="footer-container">
        <div className="drop-off-count">
          <Text>Items On List: </Text>
          <Text strong>{dropOffList.length}</Text>
        </div>
        <div className="drop-off-details-container">
          <div className="current-location-container">
            <Text>Location:</Text>
            {location ? (
              <Text strong>{location}</Text>
            ) : (
              <Text strong type="danger">
                Please set Location
              </Text>
            )}
          </div>
          <div className="employee-id-container">
            <Text>Dropped off by:</Text>
            <Spin spinning={!authUser}>
              <Text strong>{employeeId}</Text>
            </Spin>
          </div>
        </div>
        <div className="buttons-container">
          <Button
            type="primary"
            onClick={editLocation}
            style={{ height: "auto" }}>
            Edit <br />
            Location
          </Button>
          <Button
            type="primary"
            onClick={() => setConfirmDropOff(true)}
            disabled={!location || !dropOffList.length}
            style={{ height: "auto" }}>
            Complete <br />
            Drop Off
          </Button>
        </div>
      </div>
    </div>
  );
};

DropOffListPage.propTypes = {
  dropOffList: PropTypes.arrayOf(PropTypePart),
  setDropOffList: PropTypes.func.isRequired,
  authUser: AuthUserPropType,
  removeFromDropOffList: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

DropOffListPage.defaultProps = {
  authUser: null,
  dropOffList: [],
};

export default withFirebase(
  withAuthUser(withDropOffList(withLocation(DropOffListPage))),
);

export { DropOffListPage as DropOffListPageStory };
