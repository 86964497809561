import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Button, Form } from "antd";
import EmployeeIdInput from "../EmployeeIdInput";
import "./index.less";

const { Title, Text } = Typography;

const SignDropOffModal = ({
  visible,
  onClose,
  employeeId,
  setEmployeeId,
  onDropOff,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function handleError(err) {
    if (err && err.message) {
      setErrorMessage(err.message);
    }
  }

  async function handleSubmit() {
    try {
      setIsSubmitting(true);
      const validatedValues = await form.validateFields();
      if (!validatedValues.employeeId) {
        setIsSubmitting(false);
        return;
      }
      const formattedId = validatedValues.employeeId
        .toLowerCase()
        .replace(" ", ".");
      await onDropOff(formattedId);
      form.resetFields();
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      handleError(err);
    }
  }

  const footer = (
    <>
      <Button type="secondary" onClick={onClose} disabled={isSubmitting}>
        Cancel
      </Button>
      <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
        Complete Drop-off
      </Button>
    </>
  );
  return (
    <Modal
      className="sign-drop-off-modal"
      title={<Title level={3}>Complete Drop-off</Title>}
      visible={visible}
      closable={false}
      centered
      footer={footer}
      onCancel={onClose}>
      <div className="sign-drop-off-modal-body">
        <EmployeeIdInput
          form={form}
          employeeId={employeeId}
          setEmployeeId={setEmployeeId}
          onError={handleError}
          onSubmit={handleSubmit}
          onChange={() => setErrorMessage("")}
        />
        <Text type="danger">{errorMessage}</Text>
      </div>
    </Modal>
  );
};

SignDropOffModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  setEmployeeId: PropTypes.func.isRequired,
  onDropOff: PropTypes.func.isRequired,
};

export default SignDropOffModal;
