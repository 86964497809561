import { getDateFromTimestamp } from ".";

const startLocationEntriesListener = (
  firebase,
  part,
  setLocationEntries,
  onError,
) =>
  part
    ? firebase.firestore
        .collection("parts")
        .doc(part.uid)
        .collection("locationEntries")
        .orderBy("timestamp", "desc")
        .onSnapshot(async (querySnapshot) => {
          try {
            const parsedLocationEntries = [];
            await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const locationEntry = doc.data();
                const { employeeId, employeeUid, location, timestamp } =
                  locationEntry;
                const parsedEntry = {
                  uid: doc.id,
                  employeeId,
                  employeeUid,
                  location,
                  timestamp: getDateFromTimestamp(timestamp),
                };
                parsedLocationEntries.push(parsedEntry);
              }),
            );
            setLocationEntries(parsedLocationEntries);
          } catch (err) {
            onError(err);
          }
        })
    : () => {};

export default startLocationEntriesListener;
