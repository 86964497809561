import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography } from "antd";
import formatTimestamp from "../../../../helpers/formatter/formatTimestamp";
import { getEmployeeIdFromEmail } from "../../../../helpers/user";
import ROLES from "../../../../constants/ROLES";
import PropTypeUser from "../../../../prop-types/user";
import "./index.less";

const { Title, Text } = Typography;

const DetailTitleLevel = 5;
const UserInformationModal = ({
  visible,
  onClose,
  user,
  onDelete,
  onReset,
}) => {
  if (!user) return <div />;
  const username =
    user.role === ROLES.admin ? user.email : getEmployeeIdFromEmail(user.email);

  const FooterButtons = () => (
    <>
      <Button type="primary" danger onClick={() => onDelete(user)}>
        Delete
      </Button>
      <Button type="primary" onClick={onReset}>
        {user.role === ROLES.tablet ? "Change" : "Reset"} Password
      </Button>
    </>
  );

  return (
    <Modal
      title={<Title level={2}>{username} Information</Title>}
      className="admin-user-info-modal"
      visible={visible}
      onCancel={onClose}
      centered
      footer={<FooterButtons />}>
      <div className="admin-user-info-container">
        <div className="admin-user-detail">
          <Title level={DetailTitleLevel}>Account Type</Title>
          <Text>{user.role}</Text>
        </div>
        <div className="admin-user-detail">
          <Title level={DetailTitleLevel}>Account Creation Date</Title>
          <Text>{formatTimestamp(user.creationDate, false)}</Text>
        </div>
        <div className="admin-user-detail">
          <Title level={DetailTitleLevel}>Last Activity Date</Title>
          <Text>{formatTimestamp(user.lastActivityDate, false)}</Text>
        </div>
      </div>
    </Modal>
  );
};

UserInformationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  user: PropTypeUser,
};
UserInformationModal.defaultProps = {
  user: null,
  onReset: () => {},
};

export default UserInformationModal;
