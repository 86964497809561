import React, { useState, message } from "react";
import PropTypes from "prop-types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ConfirmationModal from "../../Cross/ConfirmationModal";
import "./index.less";

const DeletePartModal = ({ visible, onClose, onDelete, deleteNum }) => {
  const [isWaiting, setIsWaiting] = useState(false);

  async function handleDelete() {
    try {
      setIsWaiting(true);
      await onDelete();
      setIsWaiting(false);
    } catch (err) {
      setIsWaiting(false);
      if (err && err.message) message.error(err.message);
    }
  }
  const CancelButton = () => (
    <Button type="secondary" onClick={onClose}>
      Cancel
    </Button>
  );
  const DeleteButton = () => (
    <Button type="primary" danger onClick={handleDelete} loading={isWaiting}>
      Delete
    </Button>
  );
  const content = {
    title: `Are you sure?${
      deleteNum && deleteNum > 1 ? ` Deleting (${deleteNum}) Parts` : ""
    }`,
    message:
      "You will lose all parts history and information once a part is deleted. This cannot be undone.",
    type: "warning",
  };

  return (
    <ConfirmationModal
      visible={visible}
      onClose={onClose}
      content={content}
      TitleIcon={() => <InfoCircleOutlined />}
      BottomButtons={() => (
        <>
          <CancelButton />
          <DeleteButton />
        </>
      )}
    />
  );
};

DeletePartModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteNum: PropTypes.number,
};

DeletePartModal.defaultProps = {
  deleteNum: 0,
};
export default DeletePartModal;
