import React from "react";
import { Button, message } from "antd";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import ROLES from "./constants/ROLES";
import { withAuthentication, withAuthUser } from "./services/Authentication";
import { withFirebase } from "./services/Firebase";

import SideMenu from "./components/SideMenu";
import * as ROUTES from "./constants/routes";
import EmployeeProfilePage from "./scenes/Employee/ProfilePage";
import SearchPartsPage from "./scenes/Employee/SearchPartsPage";
import DropOffListPage from "./scenes/Employee/DropOffListPage";
import Scanner from "./scenes/Employee/Scanner";
import TabletScannerPage from "./scenes/Tablet/ScannerPage";
import PartsManagementPage from "./scenes/Admin/PartsManagementPage";
import UserManagementPage from "./scenes/Admin/UserManagementPage";
import SignInPage from "./scenes/SignInPage";
import LandingPage from "./scenes/LandingPage";
import { DropOffListProvider } from "./services/providers/DropList";
import { LocationProvider } from "./services/providers/Location";
import {
  EMPLOYEE_MESSAGE_CONFIG,
  TABLET_MESSAGE_CONFIG,
} from "./constants/message_configs";

import ProtectedRoute from "./services/routing/ProtectedRoute";
// TODO: Make sure DropListProvider wraps around only the employee and tablet routes

const TempLanding = () => (
  <div id="temp-landing-container">
    <Button type="primary" size="large">
      <Link to={ROUTES.EMPLOYEE_SCANNER}>Employee</Link>
    </Button>
    <Button type="primary" size="large">
      <Link to={ROUTES.TABLET_SCANNER}>Tablet</Link>
    </Button>
    <Button type="primary" size="large">
      <Link to={ROUTES.ADMIN_PARTS_MANAGEMENT}>Admin</Link>
    </Button>
  </div>
);

const EmployeeRouter = () => {
  message.config(EMPLOYEE_MESSAGE_CONFIG);

  return (
    <div id="main-container">
      <div id="content">
        <Switch>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.EMPLOYEE_SCANNER} component={Scanner} />
          <Route
            path={ROUTES.EMPLOYEE_PROFILE}
            component={EmployeeProfilePage}
          />
          <Route
            path={ROUTES.EMPLOYEE_DROP_OFF_LIST}
            component={DropOffListPage}
          />
          <Route
            path={ROUTES.EMPLOYEE_SEARCH_PARTS}
            component={SearchPartsPage}
          />
        </Switch>
      </div>
      <div id="employee-nav-bar">
        <SideMenu />
      </div>
    </div>
  );
};

const TabletRouter = () => {
  message.config(TABLET_MESSAGE_CONFIG);
  return (
    <div id="main-container">
      <Route path={ROUTES.TABLET_SCANNER} component={TabletScannerPage} />
    </div>
  );
};

const AdminRouter = () => {
  message.config(TABLET_MESSAGE_CONFIG);
  return (
    <div id="admin-root">
      <Route
        path={ROUTES.ADMIN_USER_MANAGEMENT}
        component={UserManagementPage}
      />
      <Route
        path={ROUTES.ADMIN_PARTS_MANAGEMENT}
        component={PartsManagementPage}
      />
    </div>
  );
};

const App = () => (
  <>
    <Router>
      <DropOffListProvider>
        <LocationProvider>
          <Route exact path="/" component={LandingPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path="/temp-landing" component={TempLanding} />
          <ProtectedRoute
            path="/employee"
            component={EmployeeRouter}
            pageRole={ROLES.employee}
          />
          <ProtectedRoute
            path="/tablet"
            component={TabletRouter}
            pageRole={ROLES.tablet}
          />
          <ProtectedRoute
            path="/admin"
            component={AdminRouter}
            pageRole={ROLES.admin}
          />
          {/* <Route path="/admin" component={AdminRouter} /> */}
        </LocationProvider>
      </DropOffListProvider>
    </Router>
  </>
);

App.propTypes = {};

export default withFirebase(withAuthentication(withAuthUser(App)));
