import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Input, Button, Typography } from "antd";
import {
  PART_DESCRIPTION_RULES,
  PART_TAG_RULES,
  PART_PO_NUMBER_RULES,
  PART_COLOUR_RULES,
} from "../../../constants/form-rules/part";
import "./index.less";

const { Text } = Typography;

const CreateNewPartFormModal = ({ visible, onCancel, onSubmit, onError }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      setLoading(false);
      form.resetFields();
    }
  }, [visible]);

  const handleError = (err) => {
    if (onError) onError(err);
    if (err && err.message) setErrorMessage(err.message);
  };

  const handleSubmit = async () => {
    try {
      setErrorMessage(null);
      setLoading(true);
      const validatedValues = await form.validateFields();
      setLoading(false);
      if (!validatedValues) return;
      setLoading(true);
      await onSubmit(validatedValues);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  const SubmitButton = () => (
    <Button type="primary" loading={loading} onClick={handleSubmit}>
      Create New Part
    </Button>
  );

  const CancelButton = () => <Button onClick={onCancel}>Cancel</Button>;
  return (
    <Modal
      title="New Part Creation Form"
      visible={visible}
      onCancel={onCancel}
      centered
      footer={
        <>
          <CancelButton />
          <SubmitButton />
        </>
      }>
      <div id="create-new-part-modal">
        <Form
          form={form}
          name="create-new-part"
          onFinish={handleSubmit}
          onFinishFailed={handleError}>
          <Form.Item
            label="Product Order #"
            name="poNumber"
            rules={PART_PO_NUMBER_RULES}>
            <Input />
          </Form.Item>
          <Form.Item label="Part Tag #" name="partTag" rules={PART_TAG_RULES}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Part Description"
            name="description"
            rules={PART_DESCRIPTION_RULES}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Color/stain "
            name="colour"
            rules={PART_COLOUR_RULES}>
            <Input />
          </Form.Item>
        </Form>
        {errorMessage ? <Text type="danger">{errorMessage}</Text> : null}
      </div>
    </Modal>
  );
};

CreateNewPartFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

CreateNewPartFormModal.defaultProps = {
  onError: null,
};

export default CreateNewPartFormModal;
