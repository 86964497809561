import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Button } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./index.less";

const { Text } = Typography;
const OkButton = ({ onOk }) => (
  <Button type="primary" onClick={onOk}>
    Ok
  </Button>
);

OkButton.propTypes = {
  onOk: PropTypes.func.isRequired,
};

function renderIcon(type) {
  switch (type) {
    case "success":
      return <CheckCircleOutlined />;
    case "error":
      return <CloseCircleOutlined />;
    case "info":
      return <InfoCircleOutlined />;
    default:
      return <InfoCircleOutlined />;
  }
}
const ConfirmationContent = ({ type, title, message }) => (
  <div className="confirmation-content">
    <div className={`confirmation-icon ${type}-icon`}>
      {renderIcon(type)}
      {/* <TitleIcon /> */}
    </div>
    <div className="confirmation-text-container">
      <Text className="confirmation-title" strong>
        {title}
      </Text>
      {message ? <Text>{message}</Text> : null}
    </div>
  </div>
);

ConfirmationContent.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error"]),
  title: PropTypes.string,
  message: PropTypes.string,
};
ConfirmationContent.defaultProps = {
  message: null,
  type: "warning",
  title: "",
};

const ConfirmationModal = ({ visible, onClose, content, BottomButtons }) => {
  const confirmedContent = !content ? {} : content;
  return (
    <Modal
      visible={visible}
      closable={false}
      centered
      onCancel={onClose}
      footer={<BottomButtons onOk={onClose} />}>
      <ConfirmationContent
        type={confirmedContent.type}
        title={confirmedContent.title}
        message={confirmedContent.message}
      />
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    type: PropTypes.oneOf(["success", "error", "warning"]),
  }),
  BottomButtons: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  content: null,
  BottomButtons: OkButton,
};

export default ConfirmationModal;
export { ConfirmationContent };
