import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Typography } from "antd";

const { Title } = Typography;

const CreateNewAdminForm = ({ form, onSubmit, onError }) => (
  <Form
    name="create-new-admin"
    form={form}
    onFinish={onSubmit}
    onFinishFailed={onError}>
    <Form.Item
      label={<Title level={3}>Admin Email</Title>}
      name="email"
      rules={[
        { required: true },
        {
          pattern:
            // Disabled because this was copy-pasted from https://emailregex.com/ (2021/05/09) so unlikely to contain a mistake
            // eslint-disable-next-line no-control-regex
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
          message: "Please enter a valid e-mail address",
        },
      ]}>
      <Input placeholder="email@domain.com" size="large" />
    </Form.Item>
  </Form>
);

CreateNewAdminForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

CreateNewAdminForm.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
};

export default CreateNewAdminForm;
