import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AuthUserContext } from "./context";
import { Firebase } from "../Firebase";
// import ROLES from "../../constants/ROLES";

const withAuthentication = (Component) => (props) => {
  const [authUser, setAuthUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  // eslint-disable-next-line react/prop-types
  const { firebase } = props;
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const unsubscribe = firebase.auth.onAuthStateChanged(async (user) => {
      setAuthUser(user);
      if (user) {
        // eslint-disable-next-line react/prop-types
        const idToken = await firebase.auth.currentUser.getIdTokenResult();
        if (idToken) {
          const userRole = idToken.claims.role;
          setRole(userRole);
          // TODO: re-enable once I figure out why this breaks writes to db
          // if (userRole === ROLES.admin) {
          //   // eslint-disable-next-line react/prop-types
          //   firebase.updateActivityDate(user.uid);
          // }
        }
        setLoadingUser(false);
      } else {
        setAuthUser(null);
        setRole(null);
        setLoadingUser(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthUserContext.Provider value={{ authUser, role, loadingUser }}>
      <Component {...props} />
    </AuthUserContext.Provider>
  );
};

withAuthentication.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};
export default withAuthentication;
