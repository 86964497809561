/* eslint-disable react/prefer-stateless-function */
import { React, Component } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import ROLES from "../../constants/ROLES";
import { Firebase, withFirebase } from "../Firebase";
import { withAuthUser } from "../Authentication";
import AuthUserPropType from "../../prop-types/AuthUserPropType";

const checkAuthorization = (role, pageRole) => {
  if (!role || !pageRole) {
    return false;
  }
  if (
    role === ROLES.admin &&
    (pageRole === ROLES.admin ||
      pageRole === ROLES.tablet ||
      pageRole === ROLES.employee)
  ) {
    return true;
  }
  if ((role === ROLES.employee || role === ROLES.tablet) && role === pageRole) {
    return true;
  }
  return false;
};

const ProtectedRoute = ({
  // eslint-disable-next-line no-shadow
  component: Component,
  authUser,
  role,
  loadingUser,
  firebase,
  pageRole,
  ...props
}) => {
  if (loadingUser) {
    return null;
  }

  const isAuthorized = checkAuthorization(role, pageRole);
  // authenticated and authorized
  if (authUser && isAuthorized) {
    return <Route {...props} render={() => <Component {...props} />} />;
  }
  // authenticated but not authorized
  if (authUser) {
    // TODO: route to the "unauthorized access" page instead
    return <Route {...props} render={() => <Redirect to={ROUTES.SIGN_IN} />} />;
  }
  // not authenticated or authorized
  return <Route {...props} render={() => <Redirect to={ROUTES.SIGN_IN} />} />;
};

ProtectedRoute.propTypes = {
  authUser: AuthUserPropType,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  component: PropTypes.instanceOf(Component).isRequired,
  pageRole: PropTypes.instanceOf(String).isRequired,
  role: PropTypes.instanceOf(String).isRequired,
  loadingUser: PropTypes.instanceOf(Boolean).isRequired,
};

ProtectedRoute.defaultProps = {
  authUser: null,
};

export default withFirebase(withAuthUser(ProtectedRoute));
