import React from "react";

const AuthUserContext = React.createContext({});

const withAuthUser = (Component) => (props) =>
  (
    <AuthUserContext.Consumer>
      {({ authUser, role, loadingUser }) => (
        <Component
          {...props}
          authUser={authUser}
          role={role}
          loadingUser={loadingUser}
        />
      )}
    </AuthUserContext.Consumer>
  );

export { AuthUserContext, withAuthUser };
