import React, { useState } from "react";
import PropTypes from "prop-types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography, message } from "antd";
import { withFirebase, Firebase } from "../../../../services/Firebase";
import PropTypeUser from "../../../../prop-types/user";
import { getEmployeeIdFromEmail } from "../../../../helpers/user";
import ROLES from "../../../../constants/ROLES";
// import timeout from "../../../../helpers/mock/timeout";
import "./index.less";

const { Title, Text } = Typography;

const DeleteUserModal = ({ firebase, visible, onClose, user, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  if (!user) return <div />;
  const username =
    user.role === ROLES.admin ? user.email : getEmployeeIdFromEmail(user.email);

  async function handleDelete() {
    try {
      setIsDeleting(true);
      const result = await firebase.deleteUser(user.email);
      setIsDeleting(false);
      if (result) {
        message.success(`User ${username} has successfully been deleted`);
        onDelete();
        onClose();
      }
    } catch (err) {
      setIsDeleting(false);
      if (err && err.message) message.error(err.message);
    }
  }

  const CancelButton = () => (
    <Button type="secondary" onClick={onClose}>
      Cancel
    </Button>
  );
  const DeleteButton = () => (
    <Button type="primary" danger loading={isDeleting} onClick={handleDelete}>
      Delete Account
    </Button>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="617px"
      bodyStyle={{
        height: "257px",
      }}
      title={<Title level={2}>Delete User: {username}</Title>}
      centered
      footer={
        <>
          <CancelButton />
          <DeleteButton />
        </>
      }>
      <div className="admin-delete-user-modal-body">
        <InfoCircleOutlined className="admin-delete-user-modal-icon" />
        <Text>
          Are you sure you want to <strong>delete</strong>
          {` ${username}'s`} account?
        </Text>
        <Text italic>This cannot be undone.</Text>
      </div>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypeUser,
};

DeleteUserModal.defaultProps = {
  user: null,
};

export default withFirebase(DeleteUserModal);
