/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { UsersContext } from "./context";
import { Firebase } from "../../Firebase";
import { getDateFromTimestamp } from "../../../helpers/parts";

const UsersProvider = (Component) => (props) => {
  const [users, setUsers] = useState([]);
  const [authId, setAuthId] = useState(null);
  let json = [];
  const { firebase } = props;
  useEffect(async () => {
    const unsubscribeUsersListener = authId
      ? firebase.firestore
          .collection("users")
          .orderBy("role", "asc")
          .orderBy("userId", "asc")
          .onSnapshot(async (querySnapshot) => {
            try {
              await Promise.all(
                querySnapshot.docs.map(async (doc) => {
                  const data = doc.data();
                  const user = {
                    uid: doc.id,
                    userId: `${data.userId}`,
                    email: `${data.email}`,
                    role: `${data.role}`,
                    creationDate: getDateFromTimestamp(data.creationDate),
                    lastActivityDate: getDateFromTimestamp(
                      data.lastActivityDate,
                    ),
                  };
                  json.push(user);
                }),
              );
              setUsers(json);
              json = [];
            } catch (error) {
              if (error && error.message) message.error(error.message);
            }
          })
      : () => {};
    const unsubscribeAuthListener = firebase.auth.onAuthStateChanged((user) => {
      if (!user) unsubscribeUsersListener();
      setAuthId(user ? user.uid : null);
    });
    return () => {
      unsubscribeAuthListener();
      unsubscribeUsersListener();
    };
  }, [authId]);

  return (
    <UsersContext.Provider value={users}>
      <Component {...props} />
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default UsersProvider;
