import React from "react";

const UsersContext = React.createContext([]);

const withUsers = (Component) => (props) =>
  (
    <UsersContext.Consumer>
      {(users) => <Component {...props} users={users} />}
    </UsersContext.Consumer>
  );
export { UsersContext, withUsers };
