import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypeUser from "../../../../prop-types/user";
import "./index.less";
import { Firebase, withFirebase } from "../../../../services/Firebase";

const { Title, Text } = Typography;

const ResetAdminPasswordModal = ({
  firebase,
  visible,
  onClose,
  onCancel,
  user,
}) => {
  const [hasReset, setHasReset] = useState(false);
  if (!user) return <div />;

  async function handleReset() {
    try {
      await firebase.requestPasswordReset(user.email);
      setHasReset(true);
    } catch (err) {
      if (err && err.message) message.error(err.message);
    }
  }

  const CancelButton = () => (
    <Button type="secondary" onClick={onCancel}>
      Cancel
    </Button>
  );
  const ResetButton = () => (
    <Button type="primary" onClick={handleReset}>
      Confirm Reset Password
    </Button>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="617px"
      title={<Title level={2}>Admin Password Reset</Title>}
      centered
      maskClosable={false}
      footer={
        !hasReset ? (
          <>
            <CancelButton />
            <ResetButton />
          </>
        ) : (
          <Button type="primary" onClick={onClose}>
            Complete
          </Button>
        )
      }>
      <div className="admin-reset-password-modal-body">
        {!hasReset ? (
          <>
            <InfoCircleOutlined className="admin-reset-password-modal-icon" />
            <Text>
              Are you sure you want to send a reset email to {user.email}?
            </Text>
          </>
        ) : (
          <>
            <Title level={3}>Email has been sent to:</Title>
            <Title className="admin-reset-email-text" level={2}>
              {user.email}
            </Title>
            <Text>
              Please click link provided by email to change the password.
            </Text>
            <Text italic>Email may take up to a few minutes to be sent.</Text>
          </>
        )}
      </div>
    </Modal>
  );
};

ResetAdminPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  user: PropTypeUser,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

ResetAdminPasswordModal.defaultProps = {
  user: null,
  onCancel: () => {},
};

export default withFirebase(ResetAdminPasswordModal);
