import React, { useState } from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import { Typography } from "antd";
import "./index.less";
import STATUS from "../../../constants/STATUS";

const { Title } = Typography;

const QRScanner = ({
  onScan,
  delayTillReady,
  startMessage,
  showMessage,
  facingMode,
}) => {
  const [status, setStatus] = useState(STATUS.ready);
  const [statusMessage, setStatusMessage] = useState(startMessage);

  const handleError = (err) => {
    setStatus(STATUS.error);
    if (!err) {
      setStatusMessage("Unexpected Error with Scanner. Please refresh.");
      return;
    }
    if (err.name === "NotAllowedError") {
      setStatusMessage(
        "Please give camera permissions in order to use the scanner",
      );
      return;
    }
    if (err.message) {
      setStatusMessage(err.message);
    }
  };

  const readyScanner = () =>
    setTimeout(() => {
      setStatus(STATUS.ready);
      setStatusMessage(startMessage);
    }, delayTillReady);

  const handleScan = async (data) => {
    if (!data) return;
    setStatusMessage("Verifying...");
    const result = await onScan(data);
    setStatusMessage("");
    setStatus(result.status);
    switch (result.status) {
      case STATUS.success:
        readyScanner();
        break;
      case STATUS.error:
        handleError({ message: result.message });
        break;
      default:
        break;
    }

    setTimeout(() => {
      setStatus(STATUS.ready);
    }, delayTillReady);
  };

  return (
    <div className="qr-code-scanner-container">
      <div className="scanner-container">
        <div className="camera-scanner">
          {status === STATUS.ready ? (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              showViewFinder={false}
              facingMode={facingMode}
            />
          ) : null}
        </div>
        <img
          className={`scanner-frame ${status}`}
          src="/images/scanner-frame.png"
          alt="QR Code Scanner Scanner Frame"
        />
      </div>
      {showMessage ? (
        <Title className="statusMessage" level={5}>
          {statusMessage}
        </Title>
      ) : null}
    </div>
  );
};

QRScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
  delayTillReady: PropTypes.number,
  startMessage: PropTypes.string,
  showMessage: PropTypes.bool,
  facingMode: PropTypes.oneOf(["user", "environment"]),
};
QRScanner.defaultProps = {
  delayTillReady: 2000,
  startMessage: "Hover over QR Code with camera",
  showMessage: false,
  facingMode: "environment",
};
export default QRScanner;
