import React from "react";
import { Typography, Space } from "antd";
import { PropTypePart } from "../../../prop-types/part";
import "./index.less";

const { Text } = Typography;

const LastAddedPart = ({ part }) =>
  part ? (
    <div className="last-added-part-container">
      <div className="last-added-part-part-tag">
        <Space>
          <Text strong>Part #:</Text>
          <Text>{part.partTag}</Text>
        </Space>
      </div>
      <div className="last-added-part-previous-location">
        <Space wrap>
          <Text strong>Last Loc:</Text>
          <Text>{part.lastLocationEntry.location}</Text>
        </Space>
      </div>
    </div>
  ) : null;

LastAddedPart.propTypes = {
  part: PropTypePart,
};

LastAddedPart.defaultProps = {
  part: null,
};

export default LastAddedPart;
