import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Button, Divider, Badge, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import QRCodeScanner from "../../../components/Employee/QRCodeScanner";
import ManualLocationInput from "../../../components/Employee/ManualLocationInput";
import ManualPartInput from "../../../components/Employee/ManualPartInput";
import LastAddedPart from "../../../components/Employee/LastAddedPart";
import ChangePasswordModal from "../../../components/Cross/ChangePasswordModal";

import { handleLocationScan, handlePartScan } from "../../../helpers/scanner";

import { withAuthUser } from "../../../services/Authentication";
import { withFirebase } from "../../../services/Firebase";
import { withDropOffList } from "../../../services/providers/DropList";
import { withLocation } from "../../../services/providers/Location";
import { PartsProvider, withParts } from "../../../services/providers/Parts";
import ROLES from "../../../constants/ROLES";
import { PropTypePart } from "../../../prop-types/part";
import * as ROUTES from "../../../constants/routes";
import { scrollTop, snapScrollCheckiOS } from "../../../helpers/iosscroll";

import "./index.less";

const ScannerPage = ({
  parts,
  dropOffList,
  setDropOffList,
  location,
  setLocation,
}) => {
  const history = useHistory();
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const navigateToDropOffList = () => {
    history.push({
      pathname: ROUTES.EMPLOYEE_DROP_OFF_LIST,
      state: { location },
    });
  };

  useEffect(() => {
    if (snapScrollCheckiOS) {
      scrollTop();
    }
  }, []);

  const callHandleLocationScan = async (code) =>
    handleLocationScan(code, setLocation);

  const callHandlePartScan = async (code) =>
    handlePartScan(code, dropOffList, setDropOffList, parts, location);

  const handleError = (err) => {
    const msg =
      err && err.message ? err.message : "Scanner: There was an error";
    message.error(msg);
    // setTimeout(() => setStatus(STATUS.ready), delayTillReady);
  };

  const handleScan = async (code) =>
    location ? callHandlePartScan(code) : callHandleLocationScan(code);

  const lastAddedPart = _.first(dropOffList);

  return (
    <div className="employee-scanner-container">
      <ChangePasswordModal
        visible={changePasswordModal}
        setActive={setChangePasswordModal}
      />
      <div className="employee-scanner-qr-code-scanner">
        <QRCodeScanner onScan={handleScan} onError={handleError} />
      </div>
      <div className="employee-scanner-helper">
        {location ? (
          <>
            <Divider style={{ borderTopColor: "black" }}>
              <Button
                className="edit-location-button"
                type="primary"
                onClick={() => setLocation(null)}
                icon={<EditOutlined />}>
                {location}
              </Button>
            </Divider>
            <ManualPartInput
              onSubmit={callHandlePartScan}
              onSubmitFailed={handleError}
            />
            <Divider className="secondary-divider" />
            <div className="last-added-part-drop-off-container">
              <LastAddedPart part={lastAddedPart} />
              <div className="review-drop-off-button-container">
                <Badge count={dropOffList.length} showZero offset={[0, 0]}>
                  <Button
                    type="primary"
                    onClick={navigateToDropOffList}
                    style={{ height: "auto" }}>
                    Review Drop-off
                  </Button>
                </Badge>
              </div>
            </div>
          </>
        ) : (
          <ManualLocationInput
            onSubmit={callHandleLocationScan}
            onSubmitFailed={handleError}
          />
        )}
      </div>
    </div>
  );
};

ScannerPage.propTypes = {
  parts: PropTypes.arrayOf(PropTypePart),
  dropOffList: PropTypes.arrayOf(PropTypePart).isRequired,
  setDropOffList: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
};

ScannerPage.defaultProps = {
  parts: [],
};

const pageRole = ROLES.employee;

export default withFirebase(
  withAuthUser(
    withDropOffList(
      withLocation(PartsProvider(pageRole)(withParts(ScannerPage))),
    ),
  ),
);

export { ScannerPage as ScannerPageStory };
