import React from "react";

const LocationContext = React.createContext([]);

const withLocation = (Component) => (props) =>
  (
    <LocationContext.Consumer>
      {({ location, setLocation }) => (
        <Component {...props} location={location} setLocation={setLocation} />
      )}
    </LocationContext.Consumer>
  );
export { LocationContext, withLocation };
