import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Button } from "antd";
import "./index.less";

const { Title, Text } = Typography;

const ConfirmDropOffModal = ({ visible, onClose, onConfirm, saving }) => (
  <Modal
    className="confirm-drop-off-modal"
    title={
      <Title className="confirm-drop-off-header" level={3}>
        Complete Drop-off
      </Title>
    }
    visible={visible}
    centered
    footer={null}
    maskClosable={false}
    onCancel={onClose}>
    <div className="confirm-drop-off-modal-body">
      <Text strong>Are you sure you want to complete your drop-off?</Text>
      <div className="confirm-drop-off-modal-buttons-container">
        <Button type="primary" onClick={onConfirm} loading={saving}>
          Yes
        </Button>
        <Button type="secondary" onClick={onClose} disabled={saving}>
          No
        </Button>
      </div>
    </div>
  </Modal>
);

ConfirmDropOffModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default ConfirmDropOffModal;
