const focusOut = (inputElement) => {
  inputElement.addEventListener("focusout", () => {
    window.scrollTo(0, 0);
  });
};

const scrollTop = () => {
  window.scrollTo(0, 0);
};

const snapScrollCheckiOS = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  // const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

const addFocusOutEventListeners = () => {
  const inputElements = document.querySelectorAll("input");
  if (snapScrollCheckiOS) {
    inputElements.forEach((inputElement) => {
      if (inputElement.getAttribute("listener") !== "true") {
        inputElement.addEventListener("focusout", scrollTop);
      }
    });
  }
};

module.exports = {
  snapScrollCheckiOS,
  scrollTop,
  focusOut,
  addFocusOutEventListeners,
};
