import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Modal, Typography, Table, Form, Input, message } from "antd";
import ExportPart from "../ExportPart";
import DeleteButton from "../DeleteButton";
import { PropTypePart } from "../../../prop-types/part";
import { withFirebase, Firebase } from "../../../services/Firebase";
import formatTimestamp from "../../../helpers/formatter/formatTimestamp";
import validateLocation from "../../../helpers/validation/validateLocation";
import {
  PART_DESCRIPTION_RULES,
  PART_PO_NUMBER_RULES,
  PART_COLOUR_RULES,
} from "../../../constants/form-rules/part";
import startLocationEntriesListener from "../../../helpers/parts/locationEntriesListener";

import "./index.less";

const { Title, Text } = Typography;
const PartInformationModal = ({ firebase, visible, onClose, part }) => {
  const [partInfoEditMode, setPartInfoEditMode] = useState(false);
  const [savingPartInfo, setSavingPartInfo] = useState(false);
  const [partInfoForm] = Form.useForm();
  const [locationEntries, setLocationEntries] = useState([]);

  const [partsHistoryEditMode, setPartsHistoryEditMode] = useState(false);
  const [partsHistoryEditMap, setPartsHistoryEditMap] = useState({});
  const [savingPartHistory, setSavingPartHistory] = useState(false);
  const [partsHistoryForm] = Form.useForm();

  function handleError(err) {
    if (err && err.message) message.error(err.message);
  }

  useEffect(() => {
    const unsubscribeEntryListener = startLocationEntriesListener(
      firebase,
      part,
      setLocationEntries,
      handleError,
    );
    return () => unsubscribeEntryListener();
  }, [part]);

  const initHistoryMap = part
    ? _.reduce(
        part.locationEntries,
        (acc, entry) => {
          acc[entry.uid] = entry.location;
          return acc;
        },
        {},
      )
    : {};

  async function savePartInfoChanges() {
    try {
      const validated = await partInfoForm.validateFields();
      if (!validated) return false;
      setSavingPartInfo(true);
      const changedValues = _.reduce(
        Object.keys(validated),
        (acc, key) => {
          const trimmed = _.trim(validated[key]);
          if (part[key] !== trimmed) {
            acc[key] = trimmed;
          }
          return acc;
        },
        {},
      );
      if (Object.keys(changedValues).length > 0) {
        await firebase.editPart(part.uid, changedValues);
        setSavingPartInfo(false);
        setPartInfoEditMode(false);
        return true;
      }
      setSavingPartInfo(false);
      setPartInfoEditMode(false);
      return false;
    } catch (error) {
      setSavingPartInfo(false);
      if (error && error.message) message.error(error.message);
      return false;
    }
  }

  async function savePartHistoryChanges() {
    try {
      const validated = await partsHistoryForm.validateFields();
      const changedValues = _.reduce(
        Object.keys(partsHistoryEditMap),
        (acc, key) => {
          if (partsHistoryEditMap[key] !== initHistoryMap[key]) {
            acc[key] = validated[key];
          }
          return acc;
        },
        {},
      );
      if (!Object.keys(changedValues).length) return false;
      setSavingPartHistory(true);
      await firebase.editLocationEntries(part.uid, changedValues);
      setSavingPartHistory(false);
      setPartsHistoryEditMode(false);
      setPartsHistoryEditMap({});
      return true;
    } catch (error) {
      setSavingPartHistory(false);
      if (error && error.message)
        message.error("Could not delete location entry.");
      return false;
    }
  }

  async function saveAllChanges() {
    const result = await Promise.all([
      savePartHistoryChanges(),
      savePartInfoChanges(),
    ]);
    if (result[0] || result[1]) message.success("Changes successfully saved.");
  }
  function onPartHistoryChange(key, value) {
    setPartsHistoryEditMap({ ...partsHistoryEditMap, [key]: value });
  }
  async function onLocationEntryDelete(uid) {
    const result = await firebase.deleteLocationEntry(part.uid, uid);
    if (result) {
      message.success(`Successfully deleted location entry`);
    }
  }

  const columns = [
    {
      title: "Drop-off Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => `${formatTimestamp(timestamp, false)}`,
      width: `26%`,
    },
    {
      title: "Employee",
      dataIndex: "employeeId",
      key: "employeeId",
      width: `20%`,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location, record) => {
        if (partsHistoryEditMode) {
          // || partsHistoryEditMap[record.uid]) {
          return (
            <Form.Item
              name={record.uid}
              initialValue={location}
              key={record.uid}
              rules={[
                { required: true, message: "Please enter a location." },
                {
                  validator: (___, value) => {
                    if (!value || validateLocation(value))
                      return Promise.resolve();
                    return Promise.reject(
                      new Error(`${value} is not a valid location.`),
                    );
                  },
                },
              ]}>
              <Input
                onChange={(e) =>
                  onPartHistoryChange(record.uid, e.target.value)
                }
              />
            </Form.Item>
          );
        }
        return location;
      },
      width: `29%`,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <div className="action-buttons-container">
          <DeleteButton onDelete={() => onLocationEntryDelete(record.uid)} />
        </div>
      ),
      width: `25%`,
    },
  ];

  const detailTitleLevel = 5;
  return part ? (
    <Modal
      className="admin-part-info-modal"
      visible={visible}
      onCancel={onClose}
      centered
      footer={
        partInfoEditMode || partsHistoryEditMode ? (
          <div className="admin-modal-buttons-container">
            <Button
              type="default"
              onClick={() => {
                setPartInfoEditMode(false);
                setPartsHistoryEditMode(false);
                partInfoForm.resetFields();
                partsHistoryForm.resetFields();
              }}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={savingPartHistory || savingPartInfo}
              onClick={saveAllChanges}>
              Save Edits
            </Button>
          </div>
        ) : (
          <div className="admin-modal-buttons-container">
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
            <ExportPart parts={[part.uid]} />
            <Button
              type="primary"
              onClick={() => {
                setPartInfoEditMode(true);
                setPartsHistoryEditMode(true);
              }}>
              Edit
            </Button>
          </div>
        )
      }
      title={
        <Title className="admin-part-info-modal-title" level={2}>
          Part #{part.partTag} Information
        </Title>
      }
      width="617px">
      <Form form={partInfoForm} name="edit-part-info-form">
        <div className="admin-part-info-details-container">
          <div className="admin-part-info-detail">
            <Title level={detailTitleLevel}>Color/Stain</Title>
            {partInfoEditMode ? (
              <Form.Item
                name="colour"
                key="colour"
                initialValue={part.colour}
                rules={PART_COLOUR_RULES}>
                <Input allowClear />
              </Form.Item>
            ) : (
              <Text>{part.colour}</Text>
            )}
          </div>
          <div className="admin-part-info-detail">
            <Title level={detailTitleLevel}>Description</Title>
            {partInfoEditMode ? (
              <Form.Item
                name="description"
                key="description"
                initialValue={part.description}
                rules={PART_DESCRIPTION_RULES}>
                <Input />
              </Form.Item>
            ) : (
              <Text>{part.description}</Text>
            )}
          </div>
          <div className="admin-part-info-detail">
            <Title level={detailTitleLevel}>Product Order #</Title>
            {partInfoEditMode ? (
              <Form.Item
                name="poNumber"
                key="poNumber"
                initialValue={part.poNumber}
                rules={PART_PO_NUMBER_RULES}>
                <Input />
              </Form.Item>
            ) : (
              <Text>{part.poNumber}</Text>
            )}
          </div>
        </div>
      </Form>
      <div className="admin-part-info-part-history-container">
        <Title className="admin-part-info-part-history-title" level={2}>
          Parts History
        </Title>
        <Form form={partsHistoryForm} name="edit-part-history-form">
          <Table
            rowKey={(record) => record.uid}
            columns={columns}
            dataSource={locationEntries}
            pagination={{
              pageSize: 7,
            }}
          />
        </Form>
      </div>
    </Modal>
  ) : null;
};

PartInformationModal.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  part: PropTypePart,
};

PartInformationModal.defaultProps = {
  part: null,
};

PartInformationModal.defaultProps = {};
export default withFirebase(PartInformationModal);
