/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { PartsContext } from "./context";
import { Firebase } from "../../Firebase";
import LOCATIONS from "../../../constants/LOCATIONS";
import ROLES from "../../../constants/ROLES";
import { getDateFromTimestamp } from "../../../helpers/parts";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import * as DEFAULT_LAST_LOCATION_ENTRY from "../../../constants/DEFAULT_LAST_LOCATION_ENTRY";

const PartsProvider = (pageRole) => (Component) => (props) => {
  const { firebase } = props;
  const defaultQuery = firebase.firestore
    .collection("parts")
    .orderBy("partTag", "asc");
  const [parts, setParts] = useState([]);
  const [authId, setAuthId] = useState(null);
  const [query, setQuery] = useState(defaultQuery);

  useEffect(async () => {
    switch (pageRole) {
      case ROLES.admin:
        setQuery(
          firebase.firestore
            .collection("parts")
            .orderBy("poNumber", "asc")
            .orderBy("partTag", "asc"),
        );
        break;
      case ROLES.employee:
      case ROLES.tablet:
      default:
        break;
    }
    let json = [];
    const unsubscribePartsListener = authId
      ? query.onSnapshot(async (querySnapshot) => {
          try {
            await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const data = doc.data();
                const {
                  partTag,
                  description,
                  poNumber,
                  colour,
                  lastLocationEntry,
                } = data;
                const part = {
                  uid: `${doc.id}`,
                  partTag: `${partTag}`,
                  description: `${description}`,
                  poNumber: `${poNumber}`,
                  colour: `${colour}`,
                  lastLocationEntry:
                    lastLocationEntry && lastLocationEntry.location
                      ? {
                          uid: `${lastLocationEntry.uid}`,
                          employeeId: `${lastLocationEntry.employeeId}`,
                          location: `${lastLocationEntry.location}`,
                          timestamp: getDateFromTimestamp(
                            lastLocationEntry.timestamp,
                          ),
                        }
                      : DEFAULT_LAST_LOCATION_ENTRY,
                };
                if (
                  (pageRole === ROLES.employee || pageRole === ROLES.tablet) &&
                  lastLocationEntry &&
                  lastLocationEntry.location === LOCATIONS.shipped
                ) {
                  // pass
                } else {
                  json.push(part);
                }
              }),
            );
            setParts(json);
            json = [];
          } catch (error) {
            if (error && error.message) message.error(error.message);
          }
        })
      : () => {};
    const unsubscribeAuthListener = firebase.auth.onAuthStateChanged((user) => {
      if (!user) unsubscribePartsListener();
      setAuthId(user ? user.uid : null);
    });
    return () => {
      unsubscribeAuthListener();
      unsubscribePartsListener();
    };
  }, [authId]);

  return (
    <PartsContext.Provider value={parts}>
      <Component {...props} />
    </PartsContext.Provider>
  );
};

PartsProvider.propTypes = {
  authUser: AuthUserPropType,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

PartsProvider.propTypes = {
  authUser: null,
};

export default PartsProvider;
