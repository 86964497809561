const formatTimestamp = (timestamp, amPmFormat = true) => {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let hours = date.getHours();
  const isAm = hours < 12;
  if (amPmFormat) {
    hours = hours === 0 ? `12` : hours;
    hours = hours < 10 ? `0${hours}` : hours;
    hours = hours > 12 ? hours - 12 : hours;
  }
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let amPm = "";
  if (amPmFormat) {
    amPm = isAm ? " A.M" : " P.M";
  }
  return `${year}-${month}-${day} ${hours}:${minutes}${amPm}`;
};

export default formatTimestamp;
