import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Typography } from "antd";

const { Title } = Typography;

const CreateNewEmployeeForm = ({ form, onSubmit, onError }) => (
  <Form
    form={form}
    name="create-new-employee"
    onFinish={onSubmit}
    onFinishFailed={onError}
    colon={false}>
    <Form.Item
      label={<Title level={3}>Employee Username</Title>}
      name="employeeUsername"
      rules={[
        { required: true, message: "Employee Username is required." },
        {
          pattern: /^[a-zA-Z\d]+\.[a-zA-Z\d]{1,3}$/,
          message:
            "Username must be formated as follows: “nathan.b”, “nathan.bc”, “nathan.bcd”",
        },
      ]}>
      <Input size="large" placeholder="nathan.b" />
    </Form.Item>
  </Form>
);

CreateNewEmployeeForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  form: PropTypes.shape({}).isRequired,
};

CreateNewEmployeeForm.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
};

export default CreateNewEmployeeForm;
