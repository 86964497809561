import _ from "lodash";
import STATUS from "../../constants/STATUS";

class VerificationResult {
  constructor(status, msg) {
    if (!_.includes(STATUS, status))
      throw new Error(`${status} must be valid Status`);
    this.status = status;
    this.message = msg;
  }
}
export default VerificationResult;
