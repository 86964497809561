import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, Form, message, Input } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import PropTypeUser from "../../../../prop-types/user";
import "./index.less";
import { Firebase, withFirebase } from "../../../../services/Firebase";

const { Title, Text } = Typography;

const ChangeTabletPasswordModal = ({
  visible,
  onClose,
  onCancel,
  user,
  firebase,
}) => {
  const [isChanging, setIsChanging] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [form] = Form.useForm();
  if (!user) return <div />;

  async function submitChange() {
    try {
      setIsChanging(true);
      const validatedValues = await form.validateFields();
      await firebase.changePassword(user.email, validatedValues.password, true);
      setIsChanging(false);
      setPasswordChanged(true);
    } catch (err) {
      setIsChanging(false);
      if (err && err.message) message.error(err.message);
    }
  }

  function handleError(error) {
    if (error && error.message) {
      message.error(error.message);
    }
  }

  const CancelButton = () => (
    <Button type="secondary" onClick={onCancel}>
      Cancel
    </Button>
  );
  const ChangePasswordButton = () => (
    <Button type="primary" loading={isChanging} onClick={submitChange}>
      Change Password
    </Button>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="500px"
      title={<Title level={2}>Change Password </Title>}
      centered
      maskClosable={false}
      footer={
        !passwordChanged ? (
          <>
            <CancelButton />
            <ChangePasswordButton />
          </>
        ) : null
      }>
      <div className="admin-change-password-modal-body">
        {!passwordChanged ? (
          <Form
            form={form}
            onFinish={submitChange}
            onFinishFailed={handleError}
            colon={false}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Password is required." },
                { min: 8, message: "Password must be at least 8 characters." },
              ]}
              label={<Title level={4}>New Password</Title>}>
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password.",
                },
                ({ getFieldValue }) => ({
                  validator(__, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match.",
                      ),
                    );
                  },
                }),
              ]}
              label={<Title level={4}>Re-Type New Password</Title>}>
              <Input.Password size="large" />
            </Form.Item>
          </Form>
        ) : (
          <>
            <CheckCircleOutlined className="admin-change-password-modal-icon" />
            <Text strong>Your password has been changed</Text>
          </>
        )}
      </div>
    </Modal>
  );
};

ChangeTabletPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  user: PropTypeUser,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

ChangeTabletPasswordModal.defaultProps = {
  user: null,
  onCancel: () => {},
};

export default withFirebase(ChangeTabletPasswordModal);
