import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DropOffListContext } from "./context";

const DropOffListKey = "kbc-drop-off-list";
const DropOffListProvider = ({ children }) => {
  const initDropOffList = JSON.parse(
    localStorage.getItem(DropOffListKey) || "[]",
  );
  const [dropOffList, setContextDropOffList] = useState(initDropOffList);

  const setDropOffList = (newDropOffList) => {
    localStorage.setItem(DropOffListKey, JSON.stringify(newDropOffList));
    setContextDropOffList(newDropOffList);
  };

  const removeFromDropOffList = (partTag) => {
    const filteredList = _.filter(
      dropOffList,
      (part) => part.partTag !== partTag,
    );
    setDropOffList(filteredList);
  };

  return (
    <DropOffListContext.Provider
      value={{ dropOffList, setDropOffList, removeFromDropOffList }}>
      {children}
    </DropOffListContext.Provider>
  );
};

DropOffListProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default DropOffListProvider;
