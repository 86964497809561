import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, Steps, Form, message } from "antd";
import generator from "generate-password";
import { withFirebase, Firebase } from "../../../../services/Firebase";
import ROLES from "../../../../constants/ROLES";
import SelectUserType from "../SelectUserType";
import CreateNewAdminForm from "../CreateNewAdminForm";
import CreateNewEmployeeForm from "../CreateNewEmployeeForm";
import CreateNewTabletForm from "../CreateNewTabletForm";
import "./index.less";

const { Title, Text } = Typography;
const { Step } = Steps;

const CreateUserModal = ({ firebase, visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userType, setUserType] = useState(ROLES.employee);
  const [creatingUser, setCreatingUser] = useState(false);
  const [form] = Form.useForm();
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function handleClose() {
    form.resetFields();
    setCurrentStep(0);
    setGeneratedPassword("");
    setEmail("");
    setErrorMessage("");
    onClose();
  }

  function handleError(err) {
    if (err && err.message) setErrorMessage(err.message);
  }

  async function handleSubmit() {
    try {
      setCreatingUser(true);
      setErrorMessage("");
      const validatedValues = await form.validateFields();
      let password;
      switch (userType) {
        case ROLES.employee:
          password = generator.generate({ length: 8, numbers: true });
          setGeneratedPassword(password);
          if (
            await firebase.createUser(
              `${validatedValues.employeeUsername}@kekulibaycabinetry.com`,
              password,
              ROLES.employee,
            )
          ) {
            message.success(
              `Created employee ${validatedValues.employeeUsername}`,
            );
          }
          break;
        case ROLES.tablet:
          if (
            await firebase.createUser(
              `${validatedValues.tabletUsername}@kekulibaycabinetry.com`,
              validatedValues.tabletPassword,
              ROLES.tablet,
            )
          ) {
            message.success(
              `Created tablet ${validatedValues.tabletUsername}, with pw: ${validatedValues.tabletPassword}`,
            );
          }
          break;
        case ROLES.admin:
          password = generator.generate({ length: 8, numbers: true });
          setGeneratedPassword(password);
          setEmail(validatedValues.email);
          if (await firebase.createAdmin(validatedValues.email, password)) {
            message.success(`Created admin ${validatedValues.email}`);
          }
          break;
        default:
          break;
      }
      setCreatingUser(false);
      setCurrentStep(currentStep + 1);
    } catch (err) {
      setCreatingUser(false);
      handleError(err);
    }
  }

  const CancelButton = () => (
    <Button type="secondary" onClick={handleClose}>
      Cancel
    </Button>
  );
  const NextButton = () => (
    <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
      Next
    </Button>
  );

  let title;
  let content;
  let footer;
  switch (currentStep) {
    case 0:
      title = "New User Form";
      content = (
        <SelectUserType selectedUser={userType} setSelectedUser={setUserType} />
      );
      footer = (
        <>
          <CancelButton />
          <NextButton />
        </>
      );
      break;
    case 1:
      title = `New ${userType} User Form`;
      footer = (
        <>
          <Button
            type="secondary"
            disabled={creatingUser}
            onClick={() => {
              setCurrentStep(currentStep - 1);
              setErrorMessage("");
              form.resetFields();
            }}>
            Back
          </Button>
          <Button type="primary" onClick={handleSubmit} loading={creatingUser}>
            Create User
          </Button>
        </>
      );
      switch (userType) {
        case ROLES.employee:
          content = (
            <CreateNewEmployeeForm
              form={form}
              onSubmit={handleSubmit}
              onError={handleError}
            />
          );
          break;
        case ROLES.tablet:
          content = (
            <CreateNewTabletForm
              form={form}
              onSubmit={handleSubmit}
              onError={handleError}
            />
          );
          break;
        case ROLES.admin:
          content = (
            <CreateNewAdminForm
              form={form}
              onSubmit={handleSubmit}
              onError={handleError}
            />
          );
          break;
        default:
          break;
      }
      break;
    case 2:
      title = `New ${userType} User Form`;
      footer = (
        <Button type="primary" onClick={handleClose}>
          Complete
        </Button>
      );

      switch (userType) {
        case ROLES.employee:
          content = (
            <div className="admin-create-user-result">
              <Title level={3}>Generated Password</Title>
              <Title level={1}>{generatedPassword}</Title>
              <Text italic>Please write down the code</Text>
            </div>
          );
          break;
        case ROLES.tablet:
          content = (
            <Title className="admin-create-user-result" level={3}>
              Tablet user successfully created
            </Title>
          );
          break;
        case ROLES.admin:
          content = (
            <div className="admin-create-user-result">
              <Title level={4}>Confirmation Email will be sent to</Title>
              <Title level={2}>{email}</Title>
              <Title level={4}>Temporary Generated Password</Title>
              <Title level={1}>{generatedPassword}</Title>
              <Text italic>Please write down the code</Text>
            </div>
          );
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      width="617px"
      maskClosable={false}
      title={
        <>
          <Title level={2}>{title}</Title>
          <Steps
            className="admin-create-user-progress-bar"
            progressDot
            current={currentStep}>
            <Step />
            <Step />
            <Step />
          </Steps>
        </>
      }
      centered
      footer={footer}>
      <div className="admin-create-user-modal-body">
        {content}
        <div className="admin-create-user-error-message">
          {errorMessage && <Text type="danger">{errorMessage}</Text>}
        </div>
      </div>
    </Modal>
  );
};

CreateUserModal.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withFirebase(CreateUserModal);
