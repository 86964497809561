import _ from "lodash";
import { PART_TAG_REGEX } from "../../constants/regex";

export const getLastLocation = (part) => {
  if (!part) return false;
  const { locationEntries } = part;
  const len = locationEntries.length;
  return locationEntries[len - 1].location;
};

export const getLastEmployee = () => {
  // TODO: get the last employee that handled  this part
};

export const getDateFromTimestamp = (timestamp) => {
  if (timestamp === null) {
    return null;
  }
  try {
    const date = timestamp.toDate();
    return date;
  } catch (error) {
    return null;
  }
};

export const getPartByTag = (tag, parts) =>
  _.find(parts, (part) => part.partTag === tag);

export const validatePart = (part) => {
  const { poNumber, partsRequired, colour, partTag } = part;
  let errorString = "";
  let isError = false;
  try {
    if (!partTag) {
      errorString += "QRCODE";
      isError = true;
    } else if (!String(partTag).match(PART_TAG_REGEX)) {
      errorString += "QRCODE ";
      isError = true;
    }
    if (!poNumber) {
      errorString += "PONUMBER ";
      isError = true;
    }
    if (!partsRequired) {
      errorString += "PARTSREQUIRED ";
      isError = true;
    }
    if (!colour) {
      errorString += "COLOUR ";
      isError = true;
    }
    if (isError) {
      return errorString;
    }
    return null;
  } catch (error) {
    return String(error);
  }
};

export const truncatePart = (part) => {
  try {
    const newPart = {};
    const { poNumber, partsRequired, colour, partTag } = part;
    newPart.partTag = partTag;
    if (poNumber) newPart.poNumber = poNumber.substring(0, 20);
    if (partsRequired) newPart.partsRequired = partsRequired.substring(0, 27);
    if (colour) newPart.colour = colour.substring(0, 21);
    if (partTag) newPart.partTag = partTag.toString();
    return newPart;
  } catch (error) {
    console.log(error);
    throw new Error(
      `Something went wrong while truncating part information. ${error}`,
    );
  }
};

export const checkDuplicatePartTags = async (parts) => {
  try {
    const partTags = [];
    await parts.map((part) => partTags.push(part.partTag));
    const duplicates = partTags.filter(
      (item, index) => partTags.indexOf(item) !== index,
    );
    return Boolean(Object.keys(duplicates).length > 0);
  } catch (error) {
    console.log(error);
    throw new Error(
      `Something went wrong while checking for duplicate parts. ${error}`,
    );
  }
};
