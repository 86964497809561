import PropTypes from "prop-types";

const PropTypeLocationEntry = PropTypes.shape({
  uid: PropTypes.string,
  employeeId: PropTypes.string,
  location: PropTypes.string,
  timestamp: PropTypes.date,
});

const PropTypePart = PropTypes.shape({
  uid: PropTypes.string,
  partTag: PropTypes.string,
  description: PropTypes.string,
  poNumber: PropTypes.string,
  colour: PropTypes.string,
  partLocationEntries: PropTypes.arrayOf(PropTypeLocationEntry),
  lastLocationEntry: PropTypeLocationEntry,
});

export { PropTypeLocationEntry, PropTypePart };
