import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Button } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./index.less";

const { Title, Text } = Typography;
const OkButton = ({ onOk }) => (
  <Button type="primary" onClick={onOk}>
    Ok
  </Button>
);

OkButton.propTypes = {
  onOk: PropTypes.func.isRequired,
};

function renderIcon(type) {
  switch (type) {
    case "success":
      return <CheckCircleOutlined />;
    case "error":
      return <CloseCircleOutlined />;
    case "info":
      return <InfoCircleOutlined />;
    default:
      return <InfoCircleOutlined />;
  }
}
const Content = ({ type, subHeader, message }) => (
  <div className="tablet-result-content">
    <div className={`tablet-result-icon ${type}-icon`}>{renderIcon(type)}</div>
    <div className="tablet-result-text-container">
      <Text className="tablet-result-subheader" strong>
        {subHeader}
      </Text>
      {message ? <Text>{message}</Text> : null}
    </div>
  </div>
);

Content.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error"]).isRequired,
  subHeader: PropTypes.string,
  message: PropTypes.string,
};
Content.defaultProps = {
  subHeader: null,
  message: null,
};

const TabletResultModal = ({ visible, onClose, content, BottomButtons }) => (
  <Modal
    title={<Title level={3}>{content.title}</Title>}
    visible={visible}
    closable={false}
    centered
    onCancel={onClose}
    footer={<BottomButtons onOk={onClose} />}>
    <Content
      type={content.type}
      subHeader={content.subHeader}
      message={content.message}
    />
  </Modal>
);

TabletResultModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    type: PropTypes.oneOf(["success", "error", "warning"]),
  }).isRequired,
  BottomButtons: PropTypes.func,
};

TabletResultModal.defaultProps = {
  BottomButtons: OkButton,
};

export default TabletResultModal;
export { Content as ConfirmationContent };
