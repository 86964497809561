/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { List, Button, Typography, Col, Row } from "antd";
import { CloseOutlined, FrownOutlined } from "@ant-design/icons";
import { PropTypePart } from "../../../prop-types/part";
import PartInfoModal from "../PartInfoModal";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import { Firebase } from "../../../services/Firebase";
import "./index.less";

const { Text, Title } = Typography;

const PartList = ({
  parts,
  onDelete,
  canEditPart,
  EmptyResult,
  authUser,
  firebase,
}) => {
  const [activePart, setActivePart] = useState(undefined);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const closeModal = () => {
    setActivePart(undefined);
    setIsInfoModalVisible(false);
  };

  let index = 0;
  return (
    <>
      <PartInfoModal
        authUser={authUser}
        firebase={firebase}
        isVisible={isInfoModalVisible}
        onCancel={closeModal}
        onOk={closeModal}
        part={activePart}
        canEditPart={canEditPart}
      />
      <List
        itemLayout="vertical"
        locale={{
          emptyText: EmptyResult ? (
            <EmptyResult />
          ) : (
            <div className="empty-results">
              <FrownOutlined className="empty-icon" />
              <Text className="empty-message">
                No Parts Found
                <br />
                Please Try Searching Again
              </Text>
            </div>
          ),
        }}
        grid={{
          column: 1,
        }}
        dataSource={parts}
        renderItem={(part) => {
          const { partTag, poNumber, description, colour, lastLocationEntry } =
            part;
          index += 1;
          return (
            <List.Item
              className="part-list-item"
              key={partTag}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Row className="title-container" span={24}>
                <Col span={20}>
                  <Title level={3}>#{partTag}</Title>
                </Col>
                <Col span={4}>
                  {onDelete ? (
                    <Button
                      className="remove-button"
                      shape="circle"
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => onDelete(part.partTag)}
                    />
                  ) : null}
                </Col>
              </Row>
              <div
                role="button"
                tabIndex={index}
                onClick={() => {
                  setActivePart(part);
                  setIsInfoModalVisible(true);
                }}>
                <Row span={24} justify="center" gutter={[0, 0]}>
                  <Col className="column-headers" span={7}>
                    <div className="column-item">
                      <Text strong>PO#</Text>
                    </div>
                    <div className="column-item">
                      <Text strong>Desc.</Text>
                    </div>
                    <div className="column-item">
                      <Text strong>Colour</Text>
                    </div>
                    <div className="column-item">
                      <Text strong>Location</Text>
                    </div>
                    <div className="column-item">
                      <Text strong>Employee</Text>
                    </div>
                  </Col>
                  <Col className="column-values" span={17}>
                    <div className="column-item">
                      <Text>{poNumber}</Text>
                    </div>
                    <div className="column-item">
                      <Text>{description}</Text>
                    </div>
                    <div className="column-item">
                      <Text>{colour}</Text>
                    </div>
                    <div className="column-item">
                      <Text>{lastLocationEntry.location}</Text>
                    </div>
                    <div className="column-item">
                      <Text>{lastLocationEntry.employeeId}</Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </List.Item>
          );
        }}
      />
    </>
  );
};

PartList.propTypes = {
  parts: PropTypes.arrayOf(PropTypePart),
  onDelete: PropTypes.func,
  canEditPart: PropTypes.bool,
  EmptyResult: PropTypes.func,
  authUser: AuthUserPropType,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

PartList.defaultProps = {
  parts: [],
  canEditPart: false,
  EmptyResult: null,
  onDelete: null,
  authUser: null,
};

export default PartList;
