import React from "react";
import {
  // eslint-disable-next-line no-unused-vars
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import { withFirebase } from "../../services/Firebase";
import * as ROUTES from "../../constants/routes";
import ROLES from "../../constants/ROLES";
import { withAuthUser } from "../../services/Authentication/context";

const LandingPage = ({ role }) => {
  let redirectTo = null;
  if (role) {
    if (role === ROLES.employee) {
      redirectTo = ROUTES.EMPLOYEE_SCANNER;
    } else if (role === ROLES.tablet) {
      redirectTo = ROUTES.TABLET_SCANNER;
    } else if (role === ROLES.admin) {
      redirectTo = ROUTES.ADMIN_PARTS_MANAGEMENT;
    }
  }
  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <Redirect to={ROUTES.SIGN_IN} />
  );
};

LandingPage.propTypes = {
  role: PropTypes.instanceOf(String).isRequired,
};

export default withFirebase(withAuthUser(LandingPage));
