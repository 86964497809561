import React from "react";

const DropOffListContext = React.createContext([]);

const withDropOffList = (Component) => (props) =>
  (
    <DropOffListContext.Consumer>
      {({ dropOffList, setDropOffList, removeFromDropOffList }) => (
        <Component
          {...props}
          dropOffList={dropOffList}
          setDropOffList={setDropOffList}
          removeFromDropOffList={removeFromDropOffList}
        />
      )}
    </DropOffListContext.Consumer>
  );
export { DropOffListContext, withDropOffList };
