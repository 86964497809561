import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import generator from "generate-password";
import { withFirebase, Firebase } from "../../../../services/Firebase";
import ROLES from "../../../../constants/ROLES";
import PropTypeUser from "../../../../prop-types/user";
import { getEmployeeIdFromEmail } from "../../../../helpers/user";
import "./index.less";

const { Title, Text } = Typography;

const ResetEmployeePasswordModal = ({
  firebase,
  visible,
  onClose,
  onCancel,
  user,
}) => {
  const [isResetting, setIsResetting] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  if (!user) return <div />;
  const username =
    user.role === ROLES.admin ? user.email : getEmployeeIdFromEmail(user.email);

  async function handleReset() {
    try {
      setIsResetting(true);

      const password = generator.generate({ length: 8, numbers: true });
      const result = await firebase.changePassword(user.email, password, false);
      if (result) {
        message.info(`Successfully reset ${username}'s password`);
      }
      setIsResetting(false);
      setGeneratedPassword(password);
    } catch (err) {
      setIsResetting(false);
      if (err && err.message) message.error(err.message);
    }
  }

  const CancelButton = () => (
    <Button type="secondary" onClick={onCancel}>
      Cancel
    </Button>
  );
  const ResetButton = () => (
    <Button type="primary" danger loading={isResetting} onClick={handleReset}>
      Confirm Reset Password
    </Button>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="617px"
      title={<Title level={2}>{username} Password Reset</Title>}
      centered
      maskClosable={false}
      footer={
        !generatedPassword ? (
          <>
            <CancelButton />
            <ResetButton />
          </>
        ) : (
          <Button type="primary" onClick={onClose}>
            Complete
          </Button>
        )
      }>
      <div className="admin-reset-password-modal-body">
        {!generatedPassword ? (
          <>
            <InfoCircleOutlined className="admin-reset-password-modal-icon" />
            <Text>
              Are you sure you want to reset {` ${username}'s`} password?
            </Text>
          </>
        ) : (
          <>
            <Title level={3}>Generated Password</Title>
            <Title className="generated-password-text" level={1}>
              {generatedPassword}
            </Title>
            <Text italic> Please write down the code</Text>
          </>
        )}
      </div>
    </Modal>
  );
};

ResetEmployeePasswordModal.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  user: PropTypeUser,
};

ResetEmployeePasswordModal.defaultProps = {
  user: null,
  onCancel: () => {},
};

export default withFirebase(ResetEmployeePasswordModal);
