import { message } from "antd";
import _ from "lodash";
import { getLocation } from "../validation/validateLocation";
import verifyPartTag from "../validation/validatePartTag";
import VerificationResult from "../classes/VerificationResult";
import STATUS from "../../constants/STATUS";

function findPart(partsList, code) {
  // TODO: may need to make this async in the future to query from db
  return _.find(partsList, ({ partTag }) => partTag === code);
}

const areStringsEqual = (a, b) => {
  const aTrimmed = _.replace(_.lowerCase(a), " ", "");
  const bTrimmed = _.replace(_.lowerCase(b), " ", "");
  return aTrimmed === bTrimmed;
};

export const handleLocationScan = async (code, setLocation) => {
  const formattedLocation = getLocation(code);
  if (!formattedLocation) {
    message.error(`Scanned code (${code}) is not a valid location`);
    return new VerificationResult(STATUS.error);
  }
  setLocation(formattedLocation);
  message.success(`Successfully set location to ${formattedLocation}`);
  return new VerificationResult(STATUS.success);
};

export const handlePartScan = async (
  code,
  dropOffList,
  setDropOffList,
  parts,
  location,
) => {
  if (!code)
    return new VerificationResult(STATUS.error, "Please enter a Part ID");
  if (!verifyPartTag(code)) {
    message.error("Please enter a four digit Part ID");
    return new VerificationResult(STATUS.error);
  }
  if (findPart(dropOffList, code)) {
    message.error(
      `This Part ID #${code} has already been added to drop off list`,
    );
    return new VerificationResult(STATUS.error);
  }
  const foundPart = findPart(parts, code);
  if (!foundPart) {
    const errorMsg = `This Part ID ${code} was not found in the system. Please check with an admin.`;
    message.error(errorMsg);
    return new VerificationResult(STATUS.error, errorMsg);
  }
  if (areStringsEqual(foundPart.lastLocationEntry.location, location)) {
    message.error(
      `Part ${code}'s last location was ${location} and cannot be added again.`,
    );
    return new VerificationResult(STATUS.error);
  }
  setDropOffList([foundPart, ...dropOffList]);
  message.success(`Successfully added part #${code} to list`);
  return new VerificationResult(STATUS.success);
};
