const {
  REACT_APP_INTERNAL_API_KEY,
  REACT_APP_INTERNAL_AUTH_DOMAIN,
  REACT_APP_INTERNAL_PROJECT_ID,
  REACT_APP_INTERNAL_STORAGE_BUCKET,
  REACT_APP_INTERNAL_MESSAGING_SENDER_ID,
} = process.env;

export default {
  apiKey: REACT_APP_INTERNAL_API_KEY,
  authDomain: REACT_APP_INTERNAL_AUTH_DOMAIN,
  projectId: REACT_APP_INTERNAL_PROJECT_ID,
  storageBucket: REACT_APP_INTERNAL_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_INTERNAL_MESSAGING_SENDER_ID,
};
