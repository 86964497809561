import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Typography, Button, Spin } from "antd";
import Branding from "../../../components/Cross/Branding";
import { withAuthUser } from "../../../services/Authentication";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { getEmployeeIdFromEmail } from "../../../helpers/user";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import * as ROUTES from "../../../constants/routes";
import "./index.less";
import { scrollTop, snapScrollCheckiOS } from "../../../helpers/iosscroll";

const { Title, Text } = Typography;
const ProfilePage = ({ firebase, authUser }) => {
  const history = useHistory();
  const signOut = () => {
    firebase.doSignOut();
    history.push(ROUTES.SIGN_IN);
  };

  useEffect(() => {
    if (snapScrollCheckiOS) {
      scrollTop();
    }
  }, []);

  const email = authUser ? authUser.email : null;
  const employeeId = getEmployeeIdFromEmail(email);
  return (
    <div id="employee-profile-page">
      <div className="header">
        <Branding />
        <Title level={1}>User Profile</Title>
      </div>
      <div className="content">
        <div id="username-container">
          <Text strong>Username:</Text>
          <Spin spinning={!employeeId}>
            <Text>{employeeId}</Text>
          </Spin>
        </div>
        <Button type="primary" onClick={signOut} size="large">
          Logout
        </Button>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  authUser: AuthUserPropType,
};

ProfilePage.defaultProps = {
  authUser: null,
};

export default withFirebase(withAuthUser(ProfilePage));
