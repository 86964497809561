import _ from "lodash";
/* eslint-disable no-console */
import config from "./config";
// eslint-disable-next-line import/order
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import LOCATIONS from "../../constants/LOCATIONS";
import {
  getDateFromTimestamp,
  validatePart,
  truncatePart,
  checkDuplicatePartTags,
} from "../../helpers/parts";
import { isEmailAddress } from "../../helpers/user";

const { NODE_ENV } = process.env;

class Firebase {
  constructor() {
    if (!firebase.apps.length) firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.functions = firebase.functions();
    this.firestore = firebase.firestore();
    if (NODE_ENV === "development") {
      const hostname = "localhost";
      const [authPort, functionsPort, firestorePort] = [9099, 5001, 9000];
      try {
        this.firestore.useEmulator(hostname, firestorePort);
        this.functions.useEmulator(hostname, functionsPort);
        this.auth.useEmulator(`http://${hostname}:${authPort}`);
        // eslint-disable-next-line no-console
        console.log("Connected to firebase emulators");
      } catch (emulatorSetupError) {
        // eslint-disable-next-line no-console
        console.log({ emulatorSetupError });
      }
    }
  }

  /** USER QUERIES */

  async createUser(email, password, role) {
    const callCreateUser = this.functions.httpsCallable("createUser");

    const user = {
      email,
      password,
      role,
    };
    await callCreateUser(user, async (resp) => resp.data.result);
  }

  async createAdmin(email, password) {
    const callCreateAdmin = this.functions.httpsCallable("createAdmin");
    const user = {
      email,
      password,
    };
    await callCreateAdmin(user, async (resp) => resp.data.result);
  }

  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(username, password) {
    let email = "";
    if (!isEmailAddress(username)) {
      email = `${username}@kekulibaycabinetry.com`;
    } else email = username;
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut = () => {
    this.auth.signOut();
  };

  doPasswordReset(email) {
    this.auth.sendPasswordResetEmail(email);
  }

  doPasswordUpdate(password) {
    this.auth.currentUser.updatePassword(password);
  }

  async deleteUser(email) {
    try {
      const deleteUser = this.functions.httpsCallable("deleteUser");
      const data = {
        email,
      };

      const response = await deleteUser(data);
      return response.data.result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async changePassword(email, password, passwordReset = false) {
    try {
      const changePassword = this.functions.httpsCallable("changePassword");
      const data = {
        email,
        password,
        passwordReset,
      };

      const response = await changePassword(data);
      return response.data.result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  setUserPasswordChanged(uid, value) {
    try {
      if (!uid) throw new Error("No user uid was passed.");
      this.firestore
        .collection("users")
        .doc(uid)
        .update({ passwordChanged: value });
    } catch (error) {
      console.log({ error });
    }
  }

  async checkEmployeeExists(employeeId) {
    console.log({ employeeId });
    const employeeRef = this.firestore
      .collection("users")
      .where("userId", "==", employeeId)
      .limit(1);
    const querySnapshot = await employeeRef.get();
    return Boolean(querySnapshot.size);
  }

  async requestPasswordReset(email) {
    if (isEmailAddress(email)) {
      const callPasswordReset = this.functions.httpsCallable("passwordReset");
      const user = {
        email,
      };
      return callPasswordReset(user, async (resp) => resp.data.result);
    }
    return null;
  }

  /** PARTS QUERIES */

  checkPartTag(partTag) {
    const partRef = this.firestore.collection("parts").doc(partTag);
    partRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return error;
      });
  }

  async getUserOnce(uid) {
    // TODO: add to firestore rules that you can only get your own user
    // for tablet and employees if possible
    try {
      const userRef = this.firestore.collection("users").doc(uid);
      const doc = await userRef.get();
      if (doc.exists) {
        return doc.data();
      }
      console.log("No such user document!");
      return null;
    } catch (error) {
      console.log("Error getting user document:", error);
      return error;
    }
  }

  getPartOnce(partTag) {
    const partRef = this.firestore.collection("parts").doc(partTag);
    partRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("found document", doc.data());
          // console.log(doc.data().description);
          return doc.data();
        }
        console.log("No such document!");
        return null;
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return error;
      });
  }

  async getPartsByUid(uids) {
    const parts = [];
    const partsCollectionRef = this.firestore.collection("parts");
    // retrieve with 'in' is limited to an array of 10 uids
    const partRef =
      uids.length > 10
        ? partsCollectionRef
        : partsCollectionRef.where(
            firebase.firestore.FieldPath.documentId(),
            "in",
            uids,
          );
    const querySnapshot = await partRef.get();
    querySnapshot.forEach(async (doc) => {
      if (!_.includes(uids, doc.id)) return;
      const data = doc.data();
      const { partTag, description, poNumber, colour, lastLocationEntry } =
        data;
      const part = {
        uid: `${doc.id}`,
        partTag: `${partTag}`,
        description: `${description}`,
        poNumber: `${poNumber}`,
        colour: `${colour}`,
        lastLocationEntry: lastLocationEntry
          ? {
              uid: `${lastLocationEntry.uid}`,
              employeeId: `${lastLocationEntry.employeeId}`,
              location: `${lastLocationEntry.location}`,
              timestamp: getDateFromTimestamp(lastLocationEntry.timestamp),
            }
          : null,
      };
      parts.push(part);
    });
    const completedParts = await Promise.all(
      _.map(parts, async (part) => {
        const entriesSnapshot = await this.firestore
          .collection("parts")
          .doc(part.uid)
          .collection("locationEntries")
          .orderBy("timestamp", "desc")
          .get();
        const locationEntries = [];
        entriesSnapshot.forEach((subDoc) => {
          const subData = subDoc.data();
          const locationEntry = {
            uid: `${subDoc.id}`,
            employeeId: `${subData.employeeId}`,
            location: `${subData.location}`,
            timestamp: getDateFromTimestamp(subData.timestamp),
          };
          locationEntries.push(locationEntry);
        });
        return { ...part, locationEntries };
      }),
    );
    return completedParts;
  }

  getAllPartsOnce() {
    const json = [];
    const partRef = this.firestore
      .collection("parts")
      .where("lastLocationEntry.location", "!=", LOCATIONS.shipped);
    return partRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const { partTag, description, poNumber, colour, lastLocationEntry } =
            data;
          const part = {
            uid: `${doc.id}`,
            partTag: `${partTag}`,
            description: `${description}`,
            poNumber: `${poNumber}`,
            colour: `${colour}`,
            lastLocationEntry: lastLocationEntry
              ? {
                  uid: `${lastLocationEntry.uid}`,
                  employeeId: `${lastLocationEntry.employeeId}`,
                  location: `${lastLocationEntry.location}`,
                  timestamp: getDateFromTimestamp(lastLocationEntry.timestamp),
                }
              : null,
          };
          doc.ref
            .collection("locationEntries")
            .orderBy("timestamp", "desc")
            .get()
            .then((subQuerySnapshot) => {
              const locationEntries = [];
              subQuerySnapshot.forEach((subDoc) => {
                const subData = subDoc.data();
                const locationEntry = {
                  uid: `${subDoc.id}`,
                  employeeId: `${subData.employeeId}`,
                  location: `${subData.location}`,
                  timestamp: getDateFromTimestamp(subData.timestamp),
                };
                locationEntries.push(locationEntry);
              });
              part.locationEntries = locationEntries;
            });
          json.push(part);
        });
        return json;
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        return error;
      });
  }

  async submitDropOffList(location, employeeId, locationEntries) {
    try {
      const date = new Date();
      const newLastLocation = {
        employeeId,
        location,
        timestamp: date,
      };
      const batch = this.firestore.batch();
      const batchPromises = [];

      // check if employee exists
      const employeeExists = await this.checkEmployeeExists(employeeId);
      if (!employeeExists) {
        throw new Error(`Employee ${employeeId} doesn't exist.`);
      }

      await Promise.all(
        locationEntries.map(async (locationEntry) => {
          const partSnapshot = await this.firestore
            .collection("parts")
            .where("partTag", "==", locationEntry.partTag)
            .limit(1)
            .get();

          if (!partSnapshot.size) {
            throw new Error(`document is null`);
          }

          const [doc] = partSnapshot.docs;
          if (!doc) {
            throw new Error(
              `Could not find documentId. Part tag ${locationEntry.partTag} not found.`,
            );
          }

          // update locationEntries collection
          batchPromises.push(
            batch.set(
              doc.ref.collection("locationEntries").doc(),
              {
                employeeId: newLastLocation.employeeId,
                location: newLastLocation.location,
                timestamp: newLastLocation.timestamp,
                employeeUid: this.auth.currentUser.uid,
              },
              { merge: true },
            ),
          );
        }),
      );
      await Promise.all(batchPromises);
      await batch.commit();
      return {
        result: "success",
      };
    } catch (error) {
      console.log(error);
      return {
        result: "failed",
        message: error.message,
      };
    }
  }

  async getDocumentIds(locationEntries) {
    const documentIds = [];
    await Promise.all(
      locationEntries.map(async (locationEntry) => {
        try {
          let partTag = null;
          try {
            partTag = parseInt(locationEntry.partTag, 10);
          } catch (error) {
            throw new Error(
              `${locationEntry.partTag} is not an integer ${error}`,
            );
          }
          const partRef = this.firestore
            .collection("parts")
            .where("partTag", "==", partTag)
            .limit(1);
          const querySnapshot = await partRef.get();
          const [doc] = querySnapshot.docs;
          if (!doc || !doc.exists) {
            throw new Error(
              `Document entry with partTag: ${partTag} was not found`,
            );
          } else {
            documentIds.push(doc.id);
            console.log(doc.id);
          }
        } catch (error) {
          console.log(error);
        }
      }),
    ).then(() => documentIds);
  }

  async editPersonalRecords(employeeId, partId, changedFields) {
    const batch = this.firestore.batch();
    const partRef = this.firestore.collection("parts").doc(partId);
    const batchUpdates = [];

    try {
      const snapshot = await partRef.get();
      if (!partId) {
        throw new Error(`docId is null `);
      }

      if (!snapshot.exists) {
        throw new Error("Document does not exist");
      }

      await Promise.all(
        Object.values(changedFields).map(async (changedField) => {
          const locationEntryRef = partRef
            .collection("locationEntries")
            .doc(Object.keys(changedField)[0]);
          const batchUpdate = batch.update(locationEntryRef, {
            location: Object.values(changedField)[0],
          });
          batchUpdates.push(batchUpdate);
        }),
      );
      await Promise.all(batchUpdates);
      await batch.commit();
      // await this.findNewLastLocationEntry(partId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /** ADMIN QUERIES */

  async getPartByPartTag(partTag) {
    try {
      const partRef = this.firestore
        .collection("parts")
        .where("partTag", "==", partTag)
        .limit(1);
      const querySnapshot = await partRef.get();
      if (querySnapshot && !querySnapshot.empty) {
        const [doc] = querySnapshot.docs;
        if (doc) {
          const data = doc.data();
          const {
            partTag: tag,
            description,
            poNumber,
            colour,
            lastLocationEntry,
          } = data;
          const part = {
            uid: `${doc.id}`,
            partTag: `${tag}`,
            description: `${description}`,
            poNumber: `${poNumber}`,
            colour: `${colour}`,
            lastLocationEntry: lastLocationEntry
              ? {
                  uid: `${lastLocationEntry.uid}`,
                  employeeId: `${lastLocationEntry.employeeId}`,
                  location: `${lastLocationEntry.location}`,
                  timestamp: getDateFromTimestamp(lastLocationEntry.timestamp),
                }
              : null,
          };
          return part;
        }
        throw new Error(`document is null`);
      }
      throw new Error(`${partTag} not found`);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async deletePartsByPartTag(partTags) {
    try {
      const batch = this.firestore.batch();
      const batchPromises = [];

      if (!partTags || partTags.length === 0) {
        throw new Error(`no part tags to delete`);
      }

      await Promise.all(
        partTags.map(async (partTag) => {
          const partSnapshot = await this.firestore
            .collection("parts")
            .where("partTag", "==", partTag)
            .limit(1)
            .get();
          if (!partSnapshot.size) {
            throw new Error(`document is null`);
          }
          const [doc] = partSnapshot.docs;
          if (!doc) {
            throw new Error(
              `Could not find documentId. Part tag ${partTag} not found.`,
            );
          }
          // get and delete locationEntries
          const locationEntriesSnapshot = await this.firestore
            .collection("parts")
            .doc(doc.id)
            .collection("locationEntries")
            .get();

          await Promise.all(
            locationEntriesSnapshot.docs.map(async (locationEntry) => {
              console.log(locationEntry);
              const locationEntryRef = locationEntry.ref;
              const batchDelete = batch.delete(locationEntryRef);
              batchPromises.push(batchDelete);
            }),
          );

          // delete part entry
          const partRef = doc.ref;
          if (partRef) {
            const batchDelete = batch.delete(partRef);
            batchPromises.push(batchDelete);
          } else {
            throw new Error(`Could not find document reference.`);
          }
        }),
      );

      await Promise.all(batchPromises);
      await batch.commit();
      return {
        result: "success",
        message: "Batch delete part(s) completed.",
      };
    } catch (error) {
      console.log(error);
      return {
        result: "failed",
        message: error.message,
      };
    }
  }

  async deletePartsById(partIds) {
    try {
      const batch = this.firestore.batch();
      const batchPromises = [];

      if (!partIds || partIds.length === 0) {
        throw new Error(`no part tags to delete`);
      }

      await Promise.all(
        partIds.map(async (partId) => {
          const doc = await this.firestore
            .collection("parts")
            .doc(partId)
            .get();

          if (!doc) {
            throw new Error(`Could not find document. ${partId} not found.`);
          }
          // get and delete locationEntries
          const locationEntriesSnapshot = await this.firestore
            .collection("parts")
            .doc(doc.id)
            .collection("locationEntries")
            .get();

          await Promise.all(
            locationEntriesSnapshot.docs.map(async (locationEntry) => {
              const locationEntryRef = locationEntry.ref;
              const batchDelete = batch.delete(locationEntryRef);
              batchPromises.push(batchDelete);
            }),
          );

          // delete part entry
          const partRef = doc.ref;
          if (partRef) {
            const batchDelete = batch.delete(partRef);
            batchPromises.push(batchDelete);
          } else {
            throw new Error(`Could not find document reference.`);
          }
        }),
      );

      await Promise.all(batchPromises);
      await batch.commit();
      return {
        result: "success",
        message: "Batch delete part(s) completed.",
      };
    } catch (error) {
      console.log(error);
      return {
        result: "failed",
        message: error.message,
      };
    }
  }

  async getDocRefByPartTag(partTag) {
    try {
      const querySnapshot = await this.firestore
        .collection("parts")
        .where("partTag", "==", partTag)
        .limit(1)
        .get();
      const [doc] = querySnapshot.docs;
      if (!doc) {
        throw new Error(
          `Could not find documentId. Part tag ${partTag} not found.`,
        );
      } else {
        return doc.ref;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async createNewPart(poNumber, partTag, description, colour) {
    // check if partTag already exists
    const partSnapshot = await this.firestore
      .collection("parts")
      .where("partTag", "==", partTag)
      .limit(1)
      .get();
    if (partSnapshot.size) {
      throw new Error(
        `Part tag ${partTag} already exists. Please use a different one.`,
      );
    }
    const date = new Date();

    // make new part
    const newPart = await this.firestore.collection("parts").add({
      poNumber,
      partTag,
      description,
      colour,
    });

    // TODO: have the rest of this trigger on create part in functions
    // const newLocationEntry = await newPart.collection("locationEntries").add({
    await newPart.collection("locationEntries").add({
      employeeId: "admin",
      location: "Programming",
      timestamp: date,
      employeeUid: this.auth.currentUser.uid,
    });

    // await newPart.update({
    //   "lastLocationEntry.documentRef": newLocationEntry,
    // });
    console.log(newPart);
    return true;
  }

  async createNewPartsAdmin(parts) {
    try {
      let errorMessage = "";
      let isRunBatch = true;
      const timeNow = firebase.firestore.Timestamp.fromDate(new Date());
      const batch = this.firestore.batch();
      const batchPromises = [];
      const isDuplicates = await checkDuplicatePartTags(parts);

      if (parts == null || Boolean(parts) === false || parts.empty) {
        throw new Error("No parts were found");
      }
      if (isDuplicates) {
        throw new Error(
          "Duplicate QR Codes found. Please check your spreadsheet for errors.",
        );
      }

      await Promise.all(
        parts.map(async (part, index) => {
          let isError = false;
          let errorString = `Line ${
            index + 1
          }: Missing or malformed field(s): `;
          const truncatedPart = truncatePart(part);
          const { poNumber, partsRequired, colour, partTag } = truncatedPart;
          // check if part already exists
          const partSnapshot = await this.firestore
            .collection("parts")
            .where("partTag", "==", partTag)
            .limit(1)
            .get();
          if (partSnapshot.size) {
            isError = true;
            errorString = `Line ${index + 1}: Part ${partTag} already exists. `;
          } else {
            // validate part data
            const partValidationErrors = validatePart(truncatedPart);
            if (partValidationErrors) {
              isError = true;
              errorString += String(partValidationErrors);
            }
          }
          // add batch promises if part passed error validation
          if (!isError) {
            const newPart = this.firestore.collection("parts").doc();
            // batch promise new part
            batchPromises.push(
              batch.set(newPart, {
                poNumber,
                partTag,
                description: partsRequired,
                lastLocationEntry: {
                  employeeId: "admin",
                  location: "Programming",
                  timestamp: timeNow,
                },
                colour,
              }),
            );
            // batch promise add new location entry to new part
            batchPromises.push(
              batch.set(newPart.collection("locationEntries").doc(), {
                employeeId: "admin",
                location: "Programming",
                timestamp: timeNow,
                employeeUid: this.auth.currentUser.uid,
              }),
              { merge: true },
            );
          } else {
            errorString += "\n";
            errorMessage += errorString;
            isRunBatch = false;
          }
        }),
      );
      if (!isRunBatch) {
        throw new Error(errorMessage);
      } else {
        await Promise.all(batchPromises);
        await batch.commit();
        return {
          result: "success",
          message: `${parts.length} part(s) added`,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: "failed",
        message: String(error),
      };
    }
  }

  async editPart(partId, changedValues) {
    try {
      if (!partId || partId.length === 0) {
        throw new Error(`no docs to edit`);
      }
      // check if part exists
      const partRef = this.firestore.collection("parts").doc(partId);
      const partDoc = await partRef.get();

      if (!partDoc || !partDoc.exists) {
        throw new Error(`document ${partId} is null`);
      }

      await partRef.set(changedValues, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async editLocationEntries(partId, changedLocations) {
    const batch = this.firestore.batch();
    const partRef = this.firestore.collection("parts").doc(partId);
    const batchPromises = [];

    try {
      if (!partId) {
        throw new Error(`docId is null `);
      }
      // check if part exists
      const partDoc = await partRef.get();
      if (!partDoc || !partDoc.exists) {
        throw new Error(`document ${partId} is null`);
      }

      // update locationEntries
      await Promise.all(
        Object.keys(changedLocations).map(async (key) => {
          console.log(key, changedLocations[key]);
          const locationEntryRef = partRef
            .collection("locationEntries")
            .doc(key);
          const batchUpdate = batch.update(locationEntryRef, {
            location: changedLocations[key],
          });
          batchPromises.push(batchUpdate);
        }),
      );
      await Promise.all(batchPromises);
      await batch.commit();
      await this.findNewLastLocationEntry(partId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async deleteLocationEntry(partId, locationEntryId) {
    // check if part exists
    const partRef = this.firestore.collection("parts").doc(partId);
    // const partDoc = await partRef.get();

    // if (!partDoc || !partDoc.exists) {
    //   throw new Error(`document ${partId} is null`);
    // }

    // // check if location entry exists
    // const locationEntry = await partRef
    //   .collection("locationEntries")
    //   .doc(locationEntryId)
    //   .get();
    // if (!locationEntry || !locationEntry.exists) {
    //   throw new Error(`location entry ${locationEntryId} doesn't exist`);
    // }
    // delete locationEntry
    const result = await partRef
      .collection("locationEntries")
      .doc(locationEntryId)
      .delete();
    console.log({ result });
    return true;
    // return await this.findNewLastLocationEntry(partId);
  }

  async findNewLastLocationEntry(partId) {
    try {
      // check if part exists
      const partRef = this.firestore.collection("parts").doc(partId);
      console.log(partId);
      // find last location entry
      const lastLocationEntryQuery = await partRef
        .collection("locationEntries")
        .orderBy("timestamp", "desc")
        .limit(1)
        .get();

      if (!lastLocationEntryQuery || lastLocationEntryQuery.empty) {
        await partRef.update({
          lastLocationEntry: {
            documentRef: null,
            employeeId: "N/A",
            location: "N/A",
            timestamp: null,
          },
        });
        return true;
      }
      const [lastLocationEntryDoc] = lastLocationEntryQuery.docs;
      if (!lastLocationEntryDoc || !lastLocationEntryDoc.exists) {
        throw new Error(`Document Error`);
      }
      // update lastLocationEntry
      const data = lastLocationEntryDoc.data();
      await partRef.update({
        lastLocationEntry: {
          documentRef: lastLocationEntryDoc.ref,
          employeeId: data.employeeId,
          location: data.location,
          timestamp: data.timestamp,
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateActivityDate(userUid) {
    try {
      if (!userUid) return false;
      await this.firestore
        .collection("users")
        .doc(userUid)
        .update({
          lastActivityDate: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      return true;
    } catch (err) {
      console.log({ err });
      return false;
    }
  }

  async importParts(file) {
    console.log("file @ firebase", file);
    try {
      const importParts = this.functions.httpsCallable("importParts");
      const data = {
        file,
      };

      const response = await importParts(data);
      console.log(response);
      return response.data.result;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  async importParts3(file) {
    try {
      const importParts = this.functions.httpsCallable(
        "middleware/importParts",
        file,
      );
      // const data = {
      //   file,
      // };

      const response = await importParts(file);
      console.log(response);
      return response.data.result;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }
}

export default Firebase;
