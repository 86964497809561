import React from "react";
import PropTypes from "prop-types";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ConfirmationModal from "../ConfirmationModal";
import "./index.less";

const ResultModal = ({ visible, onClose, result }) => {
  const TitleIcon = result.isSuccessful
    ? () => <CheckCircleOutlined />
    : () => <CloseCircleOutlined />;
  return (
    <ConfirmationModal
      content={{
        title: result.title,
        message: result.message,
        type: result.isSuccessful ? "success" : "error",
      }}
      visible={visible}
      onClose={onClose}
      TitleIcon={TitleIcon}
    />
  );
};

ResultModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  result: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    isSuccessful: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ResultModal;
