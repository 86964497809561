import { React, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Typography, message } from "antd";
import "./index.less";
import validatePartTagId from "../../../helpers/validation/validatePartTag";
import STATUS from "../../../constants/STATUS";
import { addFocusOutEventListeners } from "../../../helpers/iosscroll";

const { Title } = Typography;

const ManualPartInput = ({ onSubmit, enableHelperMessage, size }) => {
  const [form] = Form.useForm();
  useEffect(() => addFocusOutEventListeners(), [form]);

  const handleSubmit = async ({ partTag }) => {
    const result = await onSubmit(partTag);
    if (!result || !result.status) {
      message.error(
        "Manual Part Input did not return a valid Verification Result",
      );
      return;
    }
    switch (result.status) {
      case STATUS.success:
        // message.success("Part successfully added!");
        form.resetFields();
        break;
      case STATUS.error:
        form.setFields([
          {
            name: "partTag",
            errors: [
              result.message
                ? result.message
                : "Could not validate submitted Part Tag",
            ],
          },
        ]);
        break;
      default:
        break;
    }
  };

  return (
    <div className="manual-tag-input-container" direction="vertical">
      {enableHelperMessage ? (
        <Title className="help-message" level={4}>
          Part Tag (Manual Entry)
        </Title>
      ) : null}
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="partTag"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please enter a four digit Part Tag." },
            {
              validator: (_, value) => {
                if (!value || validatePartTagId(value))
                  return Promise.resolve();
                return Promise.reject(
                  new Error(`${value} is not a valid Part Tag.`),
                );
              },
            },
          ]}>
          <div className="input-button-container">
            <div className="part-tag-input-container">
              <Input size={size} placeholder="Enter Part Tag ID" />
            </div>
            <Button
              className="manual-tag-input-button"
              size={size}
              type="primary"
              htmlType="submit">
              Add To Drop-off
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

ManualPartInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  enableHelperMessage: PropTypes.bool,
  size: PropTypes.oneOf(["large", "middle", "small"]),
};

ManualPartInput.defaultProps = {
  enableHelperMessage: true,
  size: "middle",
};

export default ManualPartInput;
