import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Typography } from "antd";
import { EMPLOYEE_ID_REGEX } from "../../../constants/regex";
import "./index.less";

const { Title } = Typography;

const EmployeeIdInput = ({
  form,
  employeeId,
  setEmployeeId,
  onError,
  onSubmit,
  onChange,
}) => {
  function handleChange(e) {
    setEmployeeId(e.target.value);
    onChange();
  }

  return (
    <Form
      name="tablet-employee-id"
      form={form}
      onFinish={onSubmit}
      onFinishFailed={onError}>
      <Form.Item
        label={<Title level={4}>Please enter your employee ID</Title>}
        name="employeeId"
        className="employeeId-input"
        colon={false}
        rules={[
          {
            pattern: EMPLOYEE_ID_REGEX,
            message: "Please enter valid employee id (e.g. nathan.b)",
          },
          {
            required: true,
            message: "Please enter an Employee ID",
          },
        ]}>
        <Input
          placeholder="employee ID"
          size="large"
          onChange={handleChange}
          value={employeeId}
          allowClear
        />
      </Form.Item>
    </Form>
  );
};

EmployeeIdInput.propTypes = {
  employeeId: PropTypes.string.isRequired,
  setEmployeeId: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.shape({}).isRequired, // TODO: find actual proptype for form
};

EmployeeIdInput.defaultProps = {
  onError: () => {},
  onChange: () => {},
};

export default EmployeeIdInput;
