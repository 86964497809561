import React, { useState } from "react";
import PropTypes from "prop-types";
import { LocationContext } from "./context";

const LocationKey = "kbc-location";

const LocationProvider = ({ children }) => {
  const initLocation = localStorage.getItem(LocationKey);
  const [location, setContextLocation] = useState(initLocation);

  const setLocation = (newLocation) => {
    localStorage.setItem(LocationKey, newLocation);
    setContextLocation(newLocation);
  };

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default LocationProvider;
