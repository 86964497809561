import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./index.less";

const FilterButtons = ({ onClear, onApply, applyMode }) => {
  const [loading, setLoading] = useState(false);
  async function handleApply() {
    setLoading(true);
    await onApply();
    setLoading(false);
  }
  return (
    <div className="filter-buttons">
      <Button
        type="primary"
        ghost
        size="large"
        onClick={onClear}
        disabled={loading}>
        Clear Filters
      </Button>
      {applyMode ? (
        <Button
          type="primary"
          size="large"
          onClick={handleApply}
          loading={loading}>
          Apply Filters
        </Button>
      ) : null}
    </div>
  );
};

FilterButtons.propTypes = {
  onClear: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  applyMode: PropTypes.bool,
};

FilterButtons.defaultProps = {
  onApply: () => {},
  applyMode: false,
};

export default FilterButtons;
