import React from "react";

const PartsContext = React.createContext([]);

const withParts = (Component) => (props) =>
  (
    <PartsContext.Consumer>
      {(parts) => <Component {...props} parts={parts} />}
    </PartsContext.Consumer>
  );
export { PartsContext, withParts };
