import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Typography } from "antd";

const { Title } = Typography;

const CreateNewTabletForm = ({ form, onSubmit, onError }) => (
  <Form
    form={form}
    name="create-new-tablet"
    onFinish={onSubmit}
    onFinishFailed={onError}
    colon={false}>
    <Form.Item
      label={<Title level={3}>Tablet Username</Title>}
      name="tabletUsername"
      rules={[{ required: true }]}>
      <Input size="large" placeholder="tabletusername" />
    </Form.Item>
    <Form.Item
      label={<Title level={3}>Password</Title>}
      name="tabletPassword"
      rules={[
        { required: true, message: "Password is required." },
        { min: 8, message: "Password must be at least 8 characters." },
      ]}>
      <Input.Password size="large" />
    </Form.Item>
    <Form.Item
      name="confirm"
      label={<Title level={3}>Confirm Password</Title>}
      dependencies={["tabletPassword"]}
      hasFeedback
      rules={[
        {
          required: true,
          message: "Please confirm your password.",
        },
        ({ getFieldValue }) => ({
          validator(__, value) {
            if (!value || getFieldValue("tabletPassword") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("The two passwords that you entered do not match."),
            );
          },
        }),
      ]}>
      <Input.Password size="large" />
    </Form.Item>
  </Form>
);

CreateNewTabletForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  form: PropTypes.shape({}).isRequired,
};

CreateNewTabletForm.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
};

export default CreateNewTabletForm;
