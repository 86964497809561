export const PART_DESCRIPTION_RULES = [
  {
    required: true,
    message: "Please enter a part description",
  },
  {
    max: 27,
    message: "Description must be 27 characters or under",
  },
];

export const PART_TAG_RULES = [
  {
    required: true,
    message: "Please enter a four digit Part Tag number",
  },
  {
    pattern: /^\d{4}$/,
    message: "Part Tag must be 4 digits",
  },
];

export const PART_PO_NUMBER_RULES = [
  {
    required: true,
    message: "Please enter the Product Order number",
  },
];

export const PART_COLOUR_RULES = [
  {
    required: true,
    message: "Please enter a color or stain",
  },
  {
    max: 21,
    message: "Maximum of 21 characters allowed",
  },
];
