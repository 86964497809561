import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Modal,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  Tooltip,
  message,
} from "antd";
import { Firebase } from "../../../services/Firebase";
import { PropTypePart } from "../../../prop-types/part";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import validateLocation from "../../../helpers/validation/validateLocation";
import formatTimestamp from "../../../helpers/formatter/formatTimestamp";
import "./index.less";
import { getEmployeeIdFromEmail } from "../../../helpers/user";
import startLocationEntriesListener from "../../../helpers/parts/locationEntriesListener";
import { addFocusOutEventListeners } from "../../../helpers/iosscroll";

const { Text, Title } = Typography;

// TODO: move this to helpers

const getEditableEntries = (list, id) =>
  _.reduce(
    list,
    (acc, entry) => {
      if (id === entry.employeeId) {
        acc[entry.uid] = entry.location;
      }
      return acc;
    },
    {},
  );

const PartInformationModal = ({
  authUser,
  isVisible,
  onOk,
  onCancel,
  canEditPart,
  part,
  firebase,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [changedLocationsMap, setChangedLocationsMap] = useState({});
  const [locationEntries, setLocationEntries] = useState([]);
  const employeeId = authUser ? getEmployeeIdFromEmail(authUser.email) : "";

  const initialLocationsMap =
    employeeId && part ? getEditableEntries(locationEntries, employeeId) : {};
  const { partTag, poNumber, description, colour } = part;

  function handleError(err) {
    if (err && err.message) message.error(err.message);
  }

  useEffect(() => {
    const unsubscribeEntryListener = startLocationEntriesListener(
      firebase,
      part,
      setLocationEntries,
      handleError,
    );
    return () => {
      unsubscribeEntryListener();
    };
  }, [part]);
  useEffect(() => addFocusOutEventListeners(), [editMode]);

  const handleOnChange = (data) => {
    const key = Object.keys(data)[0];
    const value = data[key];
    if (initialLocationsMap[key] !== _.trim(value)) {
      setChangedLocationsMap({ ...changedLocationsMap, key: value });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setEditMode(false);
  };

  const handleClose = () => {
    setEditMode(false);
    onCancel();
  };

  const handleSubmitEdit = async (values) => {
    try {
      await form.validateFields();
      const changedFields = _.reduce(
        Object.keys(values),
        (acc, key) => {
          const newLocation = values[key];
          if (initialLocationsMap[key] !== newLocation) {
            acc.push({
              [key]: newLocation,
            });
          }
          return acc;
        },
        [],
      );
      if (changedFields.length === 0) {
        message.info("No fields were actually changed.");
        return;
      }
      setSaving(true);
      const saveChanges = await firebase.editPersonalRecords(
        employeeId,
        part.uid,
        changedFields,
      );
      if (saveChanges) {
        message.success("Changes successfully saved.");
      }
      setEditMode(false);
      setSaving(false);
    } catch (err) {
      if (err) message.error(err);
    }
  };

  const EditButton = () => (
    <Tooltip
      className="part-info-modal-tooltip"
      title="You can only edit your own drop-off's"
      placement="bottomRight"
      trigger={_.isEmpty(initialLocationsMap) ? "click" : []}>
      <Button
        type="primary"
        disabled={_.isEmpty(initialLocationsMap)}
        onClick={() => setEditMode(true)}>
        Edit
      </Button>
    </Tooltip>
  );
  const SaveButton = () => (
    <Button
      loading={saving}
      disabled={_.isEmpty(changedLocationsMap)}
      type="primary"
      onClick={form.submit}>
      Save Changes
    </Button>
  );
  const CloseButton = () => <Button onClick={handleClose}>Close</Button>;
  const CancelButton = () => <Button onClick={handleCancel}>Cancel</Button>;

  const columns = [
    {
      title: "Drop-off Time",
      dataIndex: "timestamp",
      key: "dropOffTime",
      render: (timestamp) => <div>{formatTimestamp(timestamp)}</div>,
      width: "35%",
    },
    {
      title: "Employee",
      dataIndex: "employeeId",
      key: "employee",
      width: "27%",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "38%",
      render: (__, record) => {
        const { uid, location } = record;
        const initialLocation = initialLocationsMap[uid];
        return editMode && initialLocation ? (
          <Form.Item
            name={uid}
            key={uid}
            initialValue={initialLocation}
            rules={[
              { required: true, message: "Please enter a location." },
              {
                validator: (___, value) => {
                  if (!value || validateLocation(value))
                    return Promise.resolve();
                  return Promise.reject(
                    new Error(`${value} is not a valid location.`),
                  );
                },
              },
            ]}>
            <Input disabled={saving} />
          </Form.Item>
        ) : (
          <Text key={uid}>{location}</Text>
        );
      },
    },
  ];

  const footer = (
    <>
      {!canEditPart && <CloseButton key="close" />}
      {canEditPart && editMode && <CancelButton key="cancel" />}
      {canEditPart && editMode && <SaveButton key="submit" />}
      {canEditPart && !editMode && <EditButton key="edit" />}
    </>
  );

  return (
    <Form
      form={form}
      name="edit-location-history"
      onFinish={handleSubmitEdit}
      onValuesChange={handleOnChange}>
      <Modal
        title={
          <Title level={3} style={{ marginBottom: 0 }}>
            Part #{partTag}
          </Title>
        }
        centered
        onOk={onOk}
        onCancel={handleClose}
        visible={isVisible}
        footer={footer}>
        <div className="part-info-container">
          <Row span={24} justify="center" gutter={[0, 0]}>
            <Col className="column-headers" span={7}>
              <div className="column-item">
                <Text strong>PO#</Text>
              </div>
              <div className="column-item">
                <Text strong>Desc.</Text>
              </div>
              <div className="column-item">
                <Text strong>Colour</Text>
              </div>
            </Col>
            <Col className="column-values" span={17}>
              <div className="column-item">
                <Text>{poNumber}</Text>
              </div>
              <div className="column-item">
                <Text>{description}</Text>
              </div>
              <div className="column-item">
                <Text>{colour}</Text>
              </div>
            </Col>
          </Row>
          <div className="parts-history-title-container">
            <Title level={2} className="parts-history-title">
              Parts History
            </Title>
          </div>
          <Table
            columns={columns}
            dataSource={locationEntries}
            bordered
            pagination={false}
            scroll={{ y: 200 }}
            rowKey={(record) => record.uid}
          />
        </div>
      </Modal>
    </Form>
  );
};

PartInformationModal.propTypes = {
  isVisible: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  part: PropTypePart,
  canEditPart: PropTypes.bool,
  authUser: AuthUserPropType,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

PartInformationModal.defaultProps = {
  isVisible: false,
  part: {
    partTag: "",
    poNumber: "",
    description: "",
    colour: "",
    locationEntries: [],
  },
  canEditPart: false,
  authUser: null,
};

export default PartInformationModal;
