import { React } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.less";

const PartSearchInput = ({ onSubmit, onChange, placeholder }) => {
  const [form] = Form.useForm();
  const handleSubmit = ({ searchTerm }) => {
    form.resetFields();
    onSubmit(searchTerm);
  };

  const handleChange = ({ searchTerm }) => onChange(searchTerm);
  return (
    <Form form={form} onFinish={handleSubmit} onValuesChange={handleChange}>
      <Form.Item name="searchTerm" style={{ margin: 0 }}>
        <div className="part-search-input-button-container">
          <Input className="part-search-input" placeholder={placeholder} />
          <Button
            className="part-search-button"
            htmlType="submit"
            icon={<SearchOutlined />}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

PartSearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

PartSearchInput.defaultProps = {
  onChange: () => {},
  placeholder: "Search by PO# or Part Tag #",
};

export default PartSearchInput;
