import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Modal, Form, Input, Typography, Button, message } from "antd";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { withAuthUser } from "../../../services/Authentication";
import AuthUserPropType from "../../../prop-types/AuthUserPropType";
import "./index.less";

const { Title, Text } = Typography;

const ChangePasswordModal = ({ visible, setActive, firebase, authUser }) => {
  const [isChanging, setIsChanging] = useState(false);
  const [form] = Form.useForm();
  const [user, setUser] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    try {
      if (!authUser) return;
      const activeUser = await firebase.getUserOnce(authUser.uid);
      if (activeUser && !activeUser.passwordChanged) {
        setActive(true);
        setUser(activeUser);
      }
    } catch (error) {
      if (error && error.message) message.error(error.message);
    }
  }, [authUser]);

  async function submitPasswordChange() {
    try {
      setIsChanging(true);
      const validatedValues = await form.validateFields();
      await firebase.changePassword(user.email, validatedValues.password, true);
      message.success("Password successfully changed!");
      form.resetFields();
      firebase.setUserPasswordChanged(authUser.uid, true);
      setIsChanging(false);
      setActive(false);
      history.go(0);
    } catch (err) {
      setIsChanging(false);
      if (err && err.message) message.error(err.message);
    }
  }

  const footer = (
    <>
      <Button type="secondary" onClick={() => setActive(false)}>
        Cancel
      </Button>
      <Button
        type="primary"
        loading={isChanging}
        onClick={submitPasswordChange}>
        Change Password
      </Button>
    </>
  );

  function handleError(err) {
    message.error(err);
  }

  return (
    <Modal
      className="change-password-modal"
      title={
        <Title className="header" level={3}>
          Set a new password
        </Title>
      }
      visible={visible}
      onCancel={() => setActive(false)}
      centered
      maskClosable={false}
      footer={footer}>
      <div className="change-password-modal-body">
        <Form
          form={form}
          name="change-password-form"
          onFinish={submitPasswordChange}
          onFinishFailed={handleError}
          colon={false}
          requiredMark>
          <Form.Item
            label={<Title level={5}>New Password</Title>}
            name="password"
            rules={[
              { required: true, message: "Password is required." },
              { min: 8, message: "Password must be at least 8 characters." },
            ]}>
            <Input.Password size="large" allowClear />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={<Title level={5}>Re-type New Password</Title>}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password.",
              },
              ({ getFieldValue }) => ({
                validator(__, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match.",
                    ),
                  );
                },
              }),
            ]}>
            <Input.Password size="large" allowClear />
          </Form.Item>
        </Form>
        <Text type="danger">
          Note: You will have to log in again upon changing password
        </Text>
      </div>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  authUser: AuthUserPropType,
};
ChangePasswordModal.defaultProps = {
  authUser: null,
};

export default withFirebase(withAuthUser(ChangePasswordModal));
