import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, message } from "antd";

const DeleteButton = ({ onDelete }) => {
  const [isWaiting, setIsWaiting] = useState(false);
  async function handleDelete() {
    try {
      setIsWaiting(true);
      await onDelete();
    } catch (err) {
      setIsWaiting(false);
      if (err && err.message) message.error(err.message);
    }
  }
  return (
    <Button loading={isWaiting} type="link" block onClick={handleDelete}>
      Delete
    </Button>
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;
