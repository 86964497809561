import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, message, Typography } from "antd";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import * as ROUTES from "../../../constants/routes";
import {
  MAXIMUM_EXCEL_FILE_SIZE_BYTES,
  XLSX_FILE_EXTENSION,
  XLS_FILE_EXTENSION,
  XLSM_FILE_EXTENSION,
} from "../../../constants/file-properties";
import { withFirebase, Firebase } from "../../../services/Firebase";

const { Text } = Typography;

const ImportParts = ({ firebase }) => {
  const fileInput = useRef(null);
  const [importing, setImporting] = useState(false);

  const handleFileInput = async (e) => {
    setImporting(true);
    try {
      if (e.target.files != null) {
        const file = e.target.files[0];
        const { name } = e.target.files[0];
        const fileExtension = String(name.split(".").pop());
        // check if file exceeded maximum file size for firestore tmp storage (10mb)
        if (file.size > MAXIMUM_EXCEL_FILE_SIZE_BYTES) {
          throw new Error("File size too big. Please choose a file under 10Mb");
          // check if file extension is an excel spreadsheet
        } else if (
          fileExtension !== XLSX_FILE_EXTENSION &&
          fileExtension !== XLSM_FILE_EXTENSION &&
          fileExtension !== XLS_FILE_EXTENSION
        ) {
          throw new Error(
            "Please upload an Excel Spreadsheet *.xlsx, *.xlsm or *.xls",
          );
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        const response = await fetch(
          // "http://localhost:5001/kbc-parts-tracker-internal/us-central1/middleware/parseExcel",
          ROUTES.MIDDLEWARE_PARSE_EXCEL_PROD,
          {
            mode: "cors",
            method: "POST",
            body: formData,
          },
        );
        const json = await response.json();
        const createParts = await firebase.createNewPartsAdmin(json);
        if (
          createParts &&
          createParts.result === "success" &&
          createParts.message
        ) {
          message.success(createParts.message);
        } else if (
          createParts &&
          createParts.result === "failed" &&
          createParts.message
        ) {
          // show the first 10 error messages
          const errorMessages = createParts.message.split("\n");
          message.config({
            maxCount: 11,
          });
          message.error("Import failed");
          errorMessages.map((msg, index) => {
            if (msg.length > 0 && index < 10) {
              return message.error(<Text>{msg}</Text>, [10]);
            }
            return null;
          });
          setImporting(false);
        }
      }
    } catch (err) {
      if (err && err.message) message.error(err.message);
      setImporting(false);
    }
  };

  const onClickClearForm = async () => {
    document.getElementById("importPartsForm").reset();
  };

  return (
    <>
      <form method="POST" encType="multipart/form-data" id="importPartsForm">
        <input
          type="file"
          name="filefield"
          onClick={onClickClearForm}
          onChange={handleFileInput}
          ref={fileInput}
          style={{ display: "none" }}
        />
      </form>

      <Button
        className="primary-color-button"
        type="secondary"
        icon={<VerticalAlignTopOutlined />}
        onClick={() => fileInput.current.click()}
        loading={importing}>
        Import
      </Button>
    </>
  );
};

ImportParts.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export default withFirebase(ImportParts);
