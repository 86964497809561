import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Table, Button, Menu, Typography } from "antd";
import {
  DeleteOutlined,
  FileAddOutlined,
  UserAddOutlined,
  UserOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import PropTypeUser from "../../../prop-types/user";
import { withFirebase } from "../../../services/Firebase";
import { UsersProvider, withUsers } from "../../../services/providers/Users";
import UserInformationModal from "../../../components/Admin/UserManagement/UserInformationModal";
import DeleteUserModal from "../../../components/Admin/UserManagement/DeleteUserModal";
import CreateUserModal from "../../../components/Admin/UserManagement/CreateUserModal";
import ResetEmployeePasswordModal from "../../../components/Admin/UserManagement/ResetEmployeePasswordModal";
import ResetAdminPasswordModal from "../../../components/Admin/UserManagement/ResetAdminPasswordModal";
import ChangeTabletPasswordModal from "../../../components/Admin/UserManagement/ChangeTabletPasswordModal";

import PageWrapper from "../PageWrapper";
import SearchInput from "../../../components/Admin/SearchInput";
import formatTimestamp from "../../../helpers/formatter/formatTimestamp";
import { getEmployeeIdFromEmail } from "../../../helpers/user";
import ROLES from "../../../constants/ROLES";
import "./index.less";

const { Text } = Typography;

const ALL_FILTERS = [ROLES.admin, ROLES.employee, ROLES.tablet];

const UserManagementPage = ({ users }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [activeUser, setActiveUser] = useState(null);
  const [activeSearchTerm, setActiveSearchTerm] = useState("");
  const [renderedUsers, setRenderedUsers] = useState([]);
  const [userTypeFilters, setUserTypeFilters] = useState([]);
  const [deleteUser, setDeleteUser] = useState(null);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  function getFilteredUsers() {
    const allFiltersActive =
      userTypeFilters.length === 3 || !userTypeFilters.length;
    const actingFilters = allFiltersActive ? ALL_FILTERS : userTypeFilters;
    if (!activeSearchTerm && allFiltersActive) {
      return users;
    }
    return _.filter(users, (user) =>
      activeSearchTerm
        ? _.includes(_.lowerCase(user.email), _.lowerCase(activeSearchTerm))
        : _.includes(actingFilters, user.role),
    );
  }

  useEffect(() => {
    const filtered = getFilteredUsers();
    setRenderedUsers(filtered);
  }, [userTypeFilters, activeSearchTerm, users]);

  function handleRowClick(record) {
    setActiveUser(record);
  }

  const columns = [
    {
      title: "Username / Email",
      dataIndex: "email",
      key: "email",
      render: (email, user) =>
        user.role === ROLES.admin ? email : getEmployeeIdFromEmail(email),
    },
    {
      title: "Account Type",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Account Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      render: (date) => formatTimestamp(date, false),
    },
    {
      title: "Last Activity Date",
      dataIndex: "lastActivityDate",
      key: "lastActivityDate",
      render: (date) => formatTimestamp(date, false),
    },
    {
      title: "Actions",
      key: "actions",
      render: (__, user) => (
        <Button
          className="rounded-icon-button primary-color-button delete-icon-button"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteUser(user);
          }}
          shape="round"
          icon={<DeleteOutlined />}
        />
      ),
    },
  ];
  function handleSearchEmpty() {
    setActiveSearchTerm("");
  }
  function handleSearchUsers(term) {
    setActiveSearchTerm(term);
  }

  const UserSearchInput = () => (
    <SearchInput
      onEmpty={handleSearchEmpty}
      onSubmit={handleSearchUsers}
      term={activeSearchTerm}
      placeholder="Search by Username / Email"
    />
  );

  const UserTopBar = () => (
    <div id="admin-user-top-bar">
      <div className="user-search-input-container">
        <UserSearchInput />
      </div>
      <div className="top-bar-buttons-container">
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setCreateUserModal(true)}>
          New User
        </Button>
      </div>
    </div>
  );

  const UserTable = () => (
    <Table
      id="user-management-table"
      rowKey={(record) => record.userId}
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
      columns={columns}
      dataSource={renderedUsers}
      onChange={(page) => setPagination(page)}
      pagination={pagination}
    />
  );

  const UserManagementContent = () => (
    <>
      <UserTopBar />
      <UserTable />
    </>
  );

  function toggleFilter(filter) {
    const filterActive = _.includes(userTypeFilters, filter);
    const newFilters = filterActive
      ? _.filter(userTypeFilters, (item) => item !== filter)
      : [...userTypeFilters, filter];
    setUserTypeFilters(newFilters);
  }

  const FilterItem = ({ label, Icon, filter, isMenuCollapsed }) => {
    const isFilterActive = _.includes(userTypeFilters, filter);
    return (
      <Menu.Item
        className={`sider-filter-select ${isFilterActive ? "selected" : ""}`}
        onClick={() => toggleFilter(filter)}
        icon={
          isMenuCollapsed ? (
            <div>
              <div className="filter-background">
                <Icon className="filter-icon" />
              </div>
            </div>
          ) : null
        }>
        {!isMenuCollapsed ? <Icon className="filter-icon" /> : null}
        <Text className="filter-text" level={3}>
          {label}
        </Text>
      </Menu.Item>
    );
  };

  FilterItem.propTypes = {
    label: PropTypes.string.isRequired,
    Icon: PropTypes.shape({}).isRequired, // hacky, should find proper fix later
    filter: PropTypes.string.isRequired,
    isMenuCollapsed: PropTypes.bool.isRequired,
  };

  const UserManagementSider = ({ collapsed }) => (
    <>
      <FilterItem
        label="Administrators"
        Icon={UserAddOutlined}
        filter={ROLES.admin}
        isMenuCollapsed={collapsed}
      />
      <FilterItem
        label="Tablet Users"
        Icon={TabletOutlined}
        filter={ROLES.tablet}
        isMenuCollapsed={collapsed}
      />
      <FilterItem
        label="Employees"
        Icon={UserOutlined}
        filter={ROLES.employee}
        isMenuCollapsed={collapsed}
      />
    </>
  );
  UserManagementSider.propTypes = {
    collapsed: PropTypes.bool.isRequired,
  };

  let ChangePasswordModal;
  if (activeUser) {
    switch (activeUser.role) {
      case ROLES.employee:
        ChangePasswordModal = ResetEmployeePasswordModal;
        break;
      case ROLES.tablet:
        ChangePasswordModal = ChangeTabletPasswordModal;
        break;
      case ROLES.admin:
        ChangePasswordModal = ResetAdminPasswordModal;
        break;
      default:
        break;
    }
  }

  return (
    <>
      {ChangePasswordModal && (
        <ChangePasswordModal
          visible={resetPasswordModal}
          onClose={() => {
            setActiveUser(false);
            setResetPasswordModal(false);
          }}
          onCancel={() => {
            setResetPasswordModal(false);
          }}
          user={activeUser}
        />
      )}
      <UserInformationModal
        visible={Boolean(activeUser)}
        onClose={() => setActiveUser(null)}
        onDelete={setDeleteUser}
        onReset={() => setResetPasswordModal(true)}
        user={activeUser}
      />
      <CreateUserModal
        visible={createUserModal}
        onClose={() => setCreateUserModal(false)}
      />
      <DeleteUserModal
        visible={Boolean(deleteUser)}
        onClose={() => setDeleteUser(null)}
        onDelete={() => setActiveUser(null)}
        user={deleteUser}
      />
      <PageWrapper
        title="User Management"
        SiderContent={UserManagementSider}
        PageContent={UserManagementContent}
      />
    </>
  );
};

UserManagementPage.propTypes = {
  users: PropTypes.arrayOf(PropTypeUser).isRequired,
};

export default withFirebase(UsersProvider(withUsers(UserManagementPage)));
