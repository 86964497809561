export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";

// Admin Routes
export const CREATE_NEW_USER = "/create-new-user";
export const CREATE_NEW_PART = "/create-new-part";
export const ADMIN_PARTS_MANAGEMENT = "/admin/parts-management";
export const ADMIN_USER_MANAGEMENT = "/admin/user-management";

// Employee routes
export const LANDING = "/employee";
export const EMPLOYEE_SCANNER = "/employee/scanner";
export const EMPLOYEE_SEARCH_PARTS = "/employee/search-parts";
export const EMPLOYEE_DROP_OFF_LIST = "/employee/drop-off-list";
export const EMPLOYEE_PROFILE = "/employee/profile";

// Tablet routes
export const TABLET_SCANNER = "/tablet/scanner";

// middleware routes
export const MIDDLEWARE_PARSE_EXCEL_INTERNAL =
  "https://us-central1-kbc-parts-tracker-internal.cloudfunctions.net/middleware/parseExcel";
export const MIDDLEWARE_PARSE_EXCEL_DEV =
  "https://us-central1-kbc-parts-tracker-dev.cloudfunctions.net/middleware/parseExcel";
export const MIDDLEWARE_PARSE_EXCEL_PROD =
  "https://us-central1-kbc-parts-tracker-prod.cloudfunctions.net/middleware/parseExcel";
