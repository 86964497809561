export const EMPLOYEE_MESSAGE_CONFIG = {
  // style: { marginTop: "85%" },
  top: "50%",
  duration: 4,
  maxCount: 3,
};

export const TABLET_MESSAGE_CONFIG = {
  top: "5%",
  duration: 4,
  maxCount: 3,
};
