import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Table,
  Button,
  Menu,
  Typography,
  Input,
  Tag,
  Badge,
  message,
} from "antd";
import {
  DeleteOutlined,
  FileAddOutlined,
  NumberOutlined,
  FileTextOutlined,
  EnvironmentFilled,
} from "@ant-design/icons";
import PageWrapper from "../PageWrapper";
import DeletePartModal from "../../../components/Admin/DeletePartModal";
import PartInformationModal from "../../../components/Admin/PartInformationModal";
import FilterButtons from "../../../components/Admin/FilterButtons";
import CreateNewPartFormModal from "../../../components/Admin/CreateNewPartFormModal";
import SearchInput from "../../../components/Admin/SearchInput";
import { withFirebase, Firebase } from "../../../services/Firebase";
import { PartsProvider, withParts } from "../../../services/providers/Parts";
import { PropTypePart } from "../../../prop-types/part";
import ROLES from "../../../constants/ROLES";
import formatTimestamp from "../../../helpers/formatter/formatTimestamp";
import { getPartByTag } from "../../../helpers/parts";
import validatePartTagId from "../../../helpers/validation/validatePartTag";
import ExportPart from "../../../components/Admin/ExportPart";
import ImportPart from "../../../components/Admin/ImportPart";
import "./index.less";

const { Title } = Typography;

const PartsManagementPage = ({ firebase, parts }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [renderedParts, setRenderedParts] = useState([]);
  const [checkedPartIds, setCheckedPartIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletePartsModal, setDeletePartsModal] = useState(false);
  const [stagedToDelete, setStagedToDelete] = useState([]);
  const [activeSearchTerm, setActiveSearchTerm] = useState(null);
  const [newPartModalVisible, setNewPartModalVisible] = useState(false);
  const [poNumberFilters, setPoNumberFilters] = useState([]);
  const [descriptionFilters, setDescriptionFilters] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  const [activePart, setActivePart] = useState(null);
  const checkedNum = checkedPartIds.length;
  function checkPartialMatchAtLeastOne(term, array) {
    if (array.length === 0) return true;
    const lowerCaseTerm = _.lowerCase(term);
    return Boolean(
      _.find(array, (item) => _.includes(lowerCaseTerm, _.lowerCase(item))),
    );
  }
  function checkMatchAtLeastOne(term, array) {
    if (array.length === 0) return true;
    const lowerCaseTerm = _.lowerCase(term);
    return Boolean(
      _.find(array, (item) => lowerCaseTerm === _.lowerCase(item)),
    );
  }

  function getFilteredParts() {
    if (
      !poNumberFilters.length &&
      !descriptionFilters.length &&
      !locationFilters.length
    )
      return parts;
    return _.filter(parts, (part) => {
      const { poNumber, description, lastLocationEntry } = part;
      return (
        checkPartialMatchAtLeastOne(poNumber, poNumberFilters) &&
        checkPartialMatchAtLeastOne(description, descriptionFilters) &&
        checkMatchAtLeastOne(
          lastLocationEntry && lastLocationEntry.location,
          locationFilters,
        )
      );
    });
  }

  function getTablePageSize() {
    // TODO: hacky way that isn't really responsive. Find way to dynamically get row height
    const defaultRowHeight = 49;
    const partsManagementTable = document.getElementById(
      "parts-management-table",
    );
    const tableHeight = partsManagementTable.clientHeight;
    return Math.floor((tableHeight - defaultRowHeight) / defaultRowHeight);
  }

  function setTablePageSize(size) {
    setPagination({ ...pagination, pageSize: size });
  }

  useEffect(() => {
    if (activePart) {
      const updatedPart = getPartByTag(activePart.partTag, parts);
      setActivePart(updatedPart);
    }
    setRenderedParts(getFilteredParts());
    setLoading(false);
    setTablePageSize(getTablePageSize());
  }, [parts, poNumberFilters, descriptionFilters, locationFilters]);

  function onCheckChange(selectedRowKeys) {
    setCheckedPartIds(selectedRowKeys);
  }

  async function handleDelete(partIds) {
    // TODO: call delete firestore function here
    // single and multiple part delete completed
    // - second stage: will need to handle delete by filters, not just by given part id
    await firebase.deletePartsById(partIds);
    setCheckedPartIds([]);
    message.success(
      `Deleted part${partIds.length > 1 ? "s" : ""} successfully!`,
    );
    setDeletePartsModal(false);
  }

  function handleDeleteButtonClick(partId) {
    const partsToStage = partId ? [partId] : checkedPartIds;
    setStagedToDelete(partsToStage);
    setDeletePartsModal(true);
  }

  function handleSearchByPartTag(tag) {
    message.info(`Search part: ${tag}`);
    setActiveSearchTerm(tag);
    // TODO: firestore search by part tag
    // This is only temp until firestore function is written
    // or maybe this can be done on loaded parts first in order to minimize firestore calls
    // but the loaded parts must be snapshots so we can trust they are up to date
    const foundPart = _.find(parts, ({ partTag }) => partTag === tag);
    setRenderedParts(foundPart ? [foundPart] : []);
  }

  function handleSearchEmpty() {
    setRenderedParts(getFilteredParts());
    setActiveSearchTerm(null);
  }

  async function handleCreateNewPart({
    poNumber,
    partTag,
    description,
    colour,
  }) {
    // TODO: replace timeout with call function to create new part here
    await firebase.createNewPart(poNumber, partTag, description, colour);
    setNewPartModalVisible(false);
    message.success(`Part ${partTag} Added Successfully`);
  }

  // function handleCreatePartSuccess(partTag) {
  //   setNewPartModalVisible(false);
  //   setResult({ title: `Part ${partTag} Added Successfully`, type: "success" });
  // }

  function handleClearFilters() {
    setPoNumberFilters([]);
    setDescriptionFilters([]);
    setLocationFilters([]);
    setRenderedParts(parts);
  }

  const columns = [
    {
      title: "Product Order",
      dataIndex: "poNumber",
      key: "poNumber",
      width: `${4 / 37}%`,
    },
    {
      title: "Part Tag",
      dataIndex: "partTag",
      key: "partTag",
      width: `${2 / 37}%`,
    },
    {
      title: "Color / Stain",
      dataIndex: "colour",
      key: "colour",
      width: `${6 / 37}%`,
    },
    {
      title: "Part Description",
      dataIndex: "description",
      key: "description",
      width: `${8 / 37}%`,
    },
    {
      title: "Location",
      dataIndex: "lastLocationEntry",
      key: "lastLocation",
      render: (entry) => (entry ? `${entry.location}` : "N/A"),
      width: `${5 / 37}%`,
    },
    {
      title: "Last Employee",
      dataIndex: "lastLocationEntry",
      key: "lastEmployee",
      render: (entry) => (entry ? `${entry.employeeId}` : "N/A"),
      width: `${4 / 37}%`,
    },
    {
      title: "Drop-off Time",
      dataIndex: "lastLocationEntry",
      key: "lastDropOffTime",
      render: (entry) =>
        entry ? `${formatTimestamp(entry.timestamp, false)}` : "N/A",
      width: `${5 / 37}%`,
    },
    {
      title: "Actions",
      key: "actions",
      width: `${2 / 37}%`,
      render: (__, part) => (
        <Button
          className="rounded-icon-button primary-color-button delete-icon-button"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteButtonClick(part.uid);
          }}
          shape="round"
          icon={<DeleteOutlined />}
        />
      ),
    },
  ];

  const PartTagSearchInput = () => (
    <SearchInput
      onEmpty={handleSearchEmpty}
      onSubmit={handleSearchByPartTag}
      term={activeSearchTerm}
      validator={(__, value) => {
        if (!value || validatePartTagId(value)) return Promise.resolve();
        return Promise.reject(new Error(`${value} is not a valid Part Tag.`));
      }}
    />
  );

  const PartsTopBar = () => (
    <div id="parts-top-bar">
      <div className="part-search-input-container">
        <PartTagSearchInput />
      </div>
      <div className="parts-top-bar-buttons-container">
        <Button
          className="primary-color-button"
          type="secondary"
          icon={<DeleteOutlined />}
          disabled={!checkedNum}
          onClick={() => handleDeleteButtonClick()}>
          Delete{checkedNum ? ` (${checkedNum})` : ""}
        </Button>
        <ExportPart parts={checkedPartIds} showQuantity />
        <ImportPart />
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setNewPartModalVisible(true)}>
          New Part
        </Button>
      </div>
    </div>
  );

  const rowSelection = {
    selectedRowKeys: checkedPartIds,
    onChange: onCheckChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    columnWidth: `${1 / 37}%`,
  };

  function handleRowClick(record) {
    setActivePart(record);
  }

  const PartsTable = () => (
    <Table
      id="parts-management-table"
      rowSelection={rowSelection}
      rowKey={(record) => record.uid}
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
      loading={loading}
      columns={columns}
      dataSource={renderedParts}
      onChange={(page) => setPagination(page)}
      pagination={pagination}
    />
  );

  const AdminContent = () => (
    <>
      <CreateNewPartFormModal
        visible={newPartModalVisible}
        onCancel={() => setNewPartModalVisible(false)}
        onSubmit={handleCreateNewPart}
      />
      <DeletePartModal
        visible={deletePartsModal}
        onClose={() => setDeletePartsModal(false)}
        onDelete={() => handleDelete(stagedToDelete)}
        deleteNum={stagedToDelete.length}
      />
      <PartInformationModal
        visible={Boolean(activePart)}
        onClose={() => setActivePart(null)}
        part={activePart}
      />
      <PartsTopBar />
      <PartsTable />
    </>
  );

  const InputTag = ({ placeholder, tags, setTags }) => {
    const [input, setInput] = useState("");
    const tagContainerRef = useRef(null);
    function handleChange(e) {
      setInput(e.target.value);
    }
    function handleSubmit() {
      // TODO: check if input already added?
      setTags([...tags, _.trim(input)]);
      setInput("");
    }
    function handleRemove(tag) {
      const filteredTags = _.filter(tags, (t) => t !== tag);
      setTags([...filteredTags]);
    }

    function mapTag(tag) {
      const tagElem = (
        <Tag closable onClose={() => handleRemove(tag)}>
          {tag}
        </Tag>
      );
      return (
        <span className="tag-item" key={tag}>
          {tagElem}
        </span>
      );
    }
    const tagChildren = _.map(tags, mapTag);
    return (
      <>
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          onPressEnter={handleSubmit}
          value={input}
          allowClear
        />
        <div className="tag-container" ref={tagContainerRef}>
          {tagChildren}
        </div>
      </>
    );
  };
  InputTag.propTypes = {
    placeholder: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    setTags: PropTypes.func.isRequired,
  };

  const PartsManagementSider = ({ collapsed }) => (
    <>
      <Menu.Item
        className="sider-input-label"
        key="po-number-filter-title"
        disabled
        icon={
          <Badge count={collapsed ? poNumberFilters.length : 0}>
            <NumberOutlined className="title-icon" />
          </Badge>
        }>
        <Title level={4} className="title-text">
          Product Order #
        </Title>
      </Menu.Item>
      <Menu.Item
        className="filter-input-and-tags-container"
        key="po-number-filter-input"
        disabled>
        {!collapsed ? (
          <InputTag
            placeholder="Filter by Product Order #"
            tags={poNumberFilters}
            setTags={setPoNumberFilters}
          />
        ) : null}
      </Menu.Item>
      <Menu.Item
        className="sider-input-label"
        key="part-description-filter-title"
        disabled
        icon={
          <Badge count={collapsed ? descriptionFilters.length : 0}>
            <FileTextOutlined className="title-icon" />
          </Badge>
        }>
        <Title level={4} className="title-text">
          Part Description
        </Title>
      </Menu.Item>
      <Menu.Item
        className="filter-input-and-tags-container"
        key="part-description-filter-input"
        disabled>
        {!collapsed ? (
          <InputTag
            placeholder="Filter by Part Description"
            tags={descriptionFilters}
            setTags={setDescriptionFilters}
          />
        ) : null}
      </Menu.Item>
      <Menu.Item
        className="sider-input-label"
        key="location-filter-title"
        disabled
        icon={
          <Badge count={collapsed ? locationFilters.length : 0}>
            <EnvironmentFilled className="title-icon" />
          </Badge>
        }>
        <Title level={4} className="title-text">
          Location
        </Title>
      </Menu.Item>
      <Menu.Item
        className="filter-input-and-tags-container"
        key="location-filter-input">
        {!collapsed ? (
          <InputTag
            // TODO: Add location validation but how will the input error look on dark green?
            placeholder="Filter by Location"
            tags={locationFilters}
            setTags={setLocationFilters}
          />
        ) : null}
      </Menu.Item>
      <Menu.Item
        className="apply-filters-buttons"
        key="apply-parts-filter-buttons">
        {!collapsed ? <FilterButtons onClear={handleClearFilters} /> : null}
      </Menu.Item>
    </>
  );
  PartsManagementSider.propTypes = {
    collapsed: PropTypes.bool.isRequired,
  };

  return (
    <PageWrapper
      title="Parts Management"
      SiderContent={PartsManagementSider}
      PageContent={AdminContent}
    />
  );
};

PartsManagementPage.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  parts: PropTypes.arrayOf(PropTypePart),
};

PartsManagementPage.defaultProps = {
  parts: [],
};

const pageRole = ROLES.admin;
export default withFirebase(
  PartsProvider(pageRole)(withParts(PartsManagementPage)),
);
