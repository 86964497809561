import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, message } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import formatTimestamp from "../../../helpers/formatter/formatTimestamp";
import { Firebase, withFirebase } from "../../../services/Firebase";
import { PropTypePart } from "../../../prop-types/part";

const EXPORT_MODE = {
  oneSheet: "One Sheet",
  partPerSheet: "Part Per Sheet",
};

const ExportParts = ({ parts, showQuantity, firebase }) => {
  const [exporting, setExporting] = useState(false);

  function partToEntries(part) {
    const { partTag, poNumber, description, colour, locationEntries } = part;
    return _.map(locationEntries, (entry) => {
      const { location, timestamp, employeeId } = entry;
      return {
        partTag,
        poNumber,
        location,
        description,
        colour,
        dropOffTime: formatTimestamp(timestamp),
        employeeId,
      };
    });
  }

  function formatPartsOneSheet(partList) {
    return _.reduce(
      partList,
      (finalList, part) => {
        const entries = partToEntries(part);
        return [...finalList, ...entries];
      },
      [],
    );
  }
  /* eslint-disable no-undef */
  // XLSX is imported by cdn
  async function handleExport() {
    if (parts.length === 0) return;
    try {
      const title = `Parts Export - ${formatTimestamp(
        new Date().getTime(),
        false,
      )}`;
      const workBook = XLSX.utils.book_new();
      workBook.Props = {
        Title: title,
        CreatedDate: new Date(),
      };
      setExporting(true);
      const queriedParts = await firebase.getPartsByUid(parts);
      const formattedParts = formatPartsOneSheet(queriedParts);
      const headerMap = {
        partTag: "Part Tag",
        poNumber: "PO Number",
        location: "Location",
        description: "Description",
        colour: "Colour",
        dropOffTime: "Drop-off Time",
        employeeId: "Employee",
      };

      const workSheet = XLSX.utils.json_to_sheet(formattedParts);

      const headerRange = XLSX.utils.decode_range(workSheet["!ref"]);
      for (let i = headerRange.s.r; i <= headerRange.e.r; i += 1) {
        const address = `${XLSX.utils.encode_col(i)}1`;
        const header = workSheet[address] && workSheet[address].v;
        if (!header) break;
        workSheet[address].v = headerMap[header];
      }

      XLSX.utils.book_append_sheet(workBook, workSheet, "Parts History");
      XLSX.writeFile(workBook, `${title}.xlsx`, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });
      setExporting(false);
    } catch (err) {
      setExporting(false);
      if (err && err.message) message.error(err.message);
    }
  }
  /* eslint-enable no-undef */

  return (
    <Button
      className="primary-color-button"
      type="secondary"
      icon={<VerticalAlignBottomOutlined />}
      disabled={!parts.length}
      loading={exporting}
      onClick={handleExport}>
      Export{showQuantity && parts.length ? ` (${parts.length})` : ""}
    </Button>
  );
  // );
};

ExportParts.propTypes = {
  parts: PropTypes.arrayOf(PropTypePart).isRequired,
  showQuantity: PropTypes.bool,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

ExportParts.defaultProps = {
  showQuantity: false,
};

export default withFirebase(ExportParts);
export { EXPORT_MODE };
